import {
  UPDATE_BUSINESS_TYPES,
  UPDATE_BUSINESS_CATEGORIES,
  UPDATE_ORG_SHORT_INFO,
  FRONTEMPLOYEE_LOADING_ON_SUBMIT,
  FRONTEMPLOYEE_GET_BY_ID,
  FRONTEMPLOYEE_ERROR,
  FRONTEMPLOYEE_IMMIGRATION_UPDATED,
  FRONTEMPLOYEE_IMMIGRATION_DATA_UPDATED,
  FRONTEMPLOYEE_IMMEGRATION_FORM_KEYS_UPDATED,
} from "actions/types";

const initialState = {
  typesOfBusinessGlobal: [],
  categoriesOfBusinessGlobal: [],
  orgShortInfo: {},
  error: {},
  loadingEmployee: false,
  currentEmployee: {},
  currentImmigration: null,
  immigrationFormKeys: [],
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case UPDATE_BUSINESS_TYPES:
      return {
        ...state,
        typesOfBusinessGlobal: payload,
      };
    case UPDATE_BUSINESS_CATEGORIES:
      return {
        ...state,
        categoriesOfBusinessGlobal: payload,
      };
    case UPDATE_ORG_SHORT_INFO:
      return {
        ...state,
        orgShortInfo: payload,
      };
    case FRONTEMPLOYEE_LOADING_ON_SUBMIT:
      return {
        ...state,
        loadingEmployee: true,
      };
    case FRONTEMPLOYEE_GET_BY_ID:
      return {
        ...state,
        currentEmployee: payload,
        loadingEmployee: false,
      };
    case FRONTEMPLOYEE_ERROR:
      return {
        ...state,
        error: payload,
        loadingEmployee: false,
      };
    case FRONTEMPLOYEE_IMMIGRATION_UPDATED:
      return {
        ...state,
        loadingEmployee: false,
      };
    case FRONTEMPLOYEE_IMMIGRATION_DATA_UPDATED:
      return {
        ...state,
        currentImmigration: payload,
      };
    case FRONTEMPLOYEE_IMMEGRATION_FORM_KEYS_UPDATED:
      return {
        ...state,
        immigrationFormKeys: payload,
      };
    default:
      return state;
  }
}
