import React from "react";
import { Row, Col, FormGroup, Label, Input, Table } from "reactstrap";
import { getImmigrationFieldValue } from "utils/immgrationHelper";
import { statesUs } from "constants/statesUs";

const Page1 = ({ setFormData1, formData1, isDisabled }) => {
  const {
    language,
    familyName,
    givenName,
    middleName,
    physicianName,
    physicianAddress,
    physicianApartment,
    physicianStreet,
    physicianFloor,
    physicianNumber,
    physicianCity,
    physicianState,
    physicianZipCode,
    physicianProvince,
    physicianPostalCode,
    physicianCountry,
    gender,
    dob,
    cityOfBirth,
    countryOfBirth,
    alienNumber,
    uscisNumber,
    vaccinationRecord,
  } = formData1;

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData1({
      ...formData1,
      [name]: type === "checkbox" ? checked : value,
    });
  };
  return (
    <React.Fragment>
      <div className="immgration-form-header mb-3 p-3">
        <div
          className="text-large"
          dangerouslySetInnerHTML={{
            __html: getImmigrationFieldValue(
              "part_1_information_about_you",
              language
            ),
          }}
        ></div>
      </div>
      <Row>
        <Col sm={12}>
          <Row>
            <Col sm={12}>
              1.
              <span className="pl-3">
                {getImmigrationFieldValue("full_legal_name", language)}
                <b>{getImmigrationFieldValue("do_not", language)}</b>{" "}
                {getImmigrationFieldValue("provide_nickname", language)}
              </span>
            </Col>

            <Col sm={12}>
              <Row className="pl-25">
                <Col md={4}>
                  <FormGroup>
                    <Label for="familyName">
                      {getImmigrationFieldValue("family_name", language)}
                    </Label>
                    <Input
                      type="text"
                      id="familyName"
                      name="familyName"
                      value={familyName}
                      onChange={handleChange}
                      disabled
                    />
                  </FormGroup>
                </Col>
                <Col md={4}>
                  <FormGroup>
                    <Label for="givenName">
                      {getImmigrationFieldValue("given_name", language)}
                    </Label>
                    <Input
                      type="text"
                      id="givenName"
                      name="givenName"
                      value={givenName}
                      onChange={handleChange}
                      disabled
                    />
                  </FormGroup>
                </Col>
                <Col md={4}>
                  <FormGroup>
                    <Label for="middleName">
                      {getImmigrationFieldValue(
                        "middle_name_if_applicable",
                        language
                      )}
                    </Label>
                    <Input
                      type="text"
                      id="middleName"
                      name="middleName"
                      value={middleName}
                      onChange={handleChange}
                      disabled
                    />
                  </FormGroup>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row>
        <Col sm={12}>
          2.
          <span className="pl-3">
            {getImmigrationFieldValue("current__physician_address", language)}
          </span>
        </Col>

        <Col sm={12}>
          <Row className="pl-25">
            <Col md={12}>
              <FormGroup>
                <Label for="physicianName">
                  {getImmigrationFieldValue("care_of", language)}
                </Label>
                <Input
                  type="text"
                  id="physicianName"
                  name="physicianName"
                  value={physicianName}
                  onChange={handleChange}
                  disabled={isDisabled}
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label for="physicianAddress">
                  {getImmigrationFieldValue("street_address", language)}
                </Label>
                <Input
                  type="text"
                  id="physicianAddress"
                  name="physicianAddress"
                  value={physicianAddress}
                  onChange={handleChange}
                  disabled={isDisabled}
                />
              </FormGroup>
            </Col>
            <Col md={1} className="d-flex align-items-center">
              <FormGroup check>
                <Label check>
                  <Input
                    type="checkbox"
                    id="physicianApartment"
                    name="physicianApartment"
                    checked={physicianApartment}
                    onChange={handleChange}
                    disabled={isDisabled}
                  />
                  {getImmigrationFieldValue("apt", language)}
                </Label>
              </FormGroup>
            </Col>
            <Col md={1} className="d-flex align-items-center">
              <FormGroup check>
                <Label check>
                  <Input
                    type="checkbox"
                    id="physicianStreet"
                    name="physicianStreet"
                    checked={physicianStreet}
                    onChange={handleChange}
                    disabled={isDisabled}
                  />
                  {getImmigrationFieldValue("ste", language)}
                </Label>
              </FormGroup>
            </Col>
            <Col md={1} className="d-flex align-items-center">
              <FormGroup check>
                <Label check>
                  <Input
                    type="checkbox"
                    id="physicianFloor"
                    name="physicianFloor"
                    checked={physicianFloor}
                    onChange={handleChange}
                    disabled={isDisabled}
                  />
                  {getImmigrationFieldValue("flr", language)}
                </Label>
              </FormGroup>
            </Col>
            <Col md={3}>
              <FormGroup>
                <Label for="physicianNumber">
                  {getImmigrationFieldValue("number", language)}
                </Label>
                <Input
                  type="text"
                  id="physicianNumber"
                  name="physicianNumber"
                  value={physicianNumber}
                  onChange={handleChange}
                  disabled={isDisabled}
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label for="physicianCity">
                  {getImmigrationFieldValue("city", language)}
                </Label>
                <Input
                  type="text"
                  id="physicianCity"
                  name="physicianCity"
                  value={physicianCity}
                  onChange={handleChange}
                  disabled={isDisabled}
                />
              </FormGroup>
            </Col>
            <Col md={2}>
              <FormGroup>
                <Label for="physicianState">
                  {getImmigrationFieldValue("state", language)}
                </Label>
                <Input
                  type="select"
                  id="physicianState"
                  name="physicianState"
                  value={physicianState}
                  onChange={handleChange}
                  disabled={isDisabled}
                >
                  <option value="">
                    {getImmigrationFieldValue("choose", language)}
                    ...
                  </option>

                  {statesUs.map((state) => {
                    return <option value={state}>{state}</option>;
                  })}
                </Input>
              </FormGroup>
            </Col>
            <Col md={2}>
              <FormGroup>
                <Label for="physicianZipCode">
                  {getImmigrationFieldValue("zip_code", language)}
                </Label>
                <Input
                  type="text"
                  id="physicianZipCode"
                  name="physicianZipCode"
                  value={physicianZipCode}
                  onChange={handleChange}
                  disabled={isDisabled}
                />
              </FormGroup>
            </Col>
            <Col md={4}>
              <FormGroup>
                <Label for="physicianProvince">
                  {getImmigrationFieldValue("province", language)}
                </Label>
                <Input
                  type="text"
                  id="physicianProvince"
                  name="physicianProvince"
                  value={physicianProvince}
                  onChange={handleChange}
                  disabled={isDisabled}
                />
              </FormGroup>
            </Col>
            <Col md={4}>
              <FormGroup>
                <Label for="physicianPostalCode">
                  {getImmigrationFieldValue("postal_code", language)}
                </Label>
                <Input
                  type="text"
                  id="physicianPostalCode"
                  name="physicianPostalCode"
                  value={physicianPostalCode}
                  onChange={handleChange}
                  disabled={isDisabled}
                />
              </FormGroup>
            </Col>
            <Col md={4}>
              <FormGroup>
                <Label for="physicianCountry">
                  {getImmigrationFieldValue("country", language)}
                </Label>
                <Input
                  type="text"
                  id="physicianCountry"
                  name="physicianCountry"
                  value={physicianCountry}
                  onChange={handleChange}
                  disabled={isDisabled}
                />
              </FormGroup>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row>
        <Col sm={12}>
          3.
          <span className="ml-2">
            {getImmigrationFieldValue("other_information", language)}
          </span>
        </Col>
        <Col sm={12}>
          <Row className="pl-25">
            <Col md={2}>
              <FormGroup>
                <Label for="gender">
                  <b>A.</b> {getImmigrationFieldValue("gender", language)}
                </Label>
                <Input
                  type="text"
                  id="gender"
                  name="gender"
                  value={
                    gender === "m"
                      ? "Male"
                      : gender === "f"
                      ? "Female"
                      : gender === "o"
                      ? "Other"
                      : "Not to say"
                  }
                  disabled
                ></Input>
              </FormGroup>
            </Col>
            <Col md={3}>
              <FormGroup>
                <Label for="dob">
                  <b>B.</b>{" "}
                  {getImmigrationFieldValue("date_of_birth", language)}
                </Label>
                <Input type="date" id="dob" name="dob" value={dob} disabled />
              </FormGroup>
            </Col>
            <Col md={4}>
              <FormGroup>
                <Label for="cityOfBirth">
                  <b>C.</b>{" "}
                  {getImmigrationFieldValue("city_of_birth", language)}
                </Label>
                <Input
                  type="text"
                  id="cityOfBirth"
                  name="cityOfBirth"
                  value={cityOfBirth}
                  onChange={handleChange}
                  disabled={isDisabled}
                />
              </FormGroup>
            </Col>
            <Col md={5}>
              <FormGroup>
                <Label for="countryOfBirth">
                  <b>D.</b>{" "}
                  {getImmigrationFieldValue("country_of_birth", language)}
                </Label>
                <Input
                  type="text"
                  id="countryOfBirth"
                  name="countryOfBirth"
                  value={countryOfBirth}
                  onChange={handleChange}
                  disabled={isDisabled}
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label for="alienNumber">
                  <b>E.</b>{" "}
                  {getImmigrationFieldValue("alien_registration", language)}
                </Label>
                <Input
                  type="text"
                  id="alienNumber"
                  name="alienNumber"
                  value={alienNumber}
                  onChange={handleChange}
                  disabled={isDisabled}
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label for="uscisNumber">
                  <b>F.</b>{" "}
                  {getImmigrationFieldValue("uscis_account_number", language)}
                </Label>
                <Input
                  type="text"
                  id="uscisNumber"
                  name="uscisNumber"
                  value={uscisNumber}
                  onChange={handleChange}
                  disabled={isDisabled}
                />
              </FormGroup>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row>
        <Col sm={12}>
          4.
          <span className="ml-3">
            {getImmigrationFieldValue("vaccination_record", language)}
          </span>
        </Col>
        <Col className="ml-5" sm={12}>
          <Row>
            <Col md={12} className="d-flex">
              <b className="pr-5">A.</b>{" "}
              <FormGroup check>
                <Label check>
                  <Input
                    type="checkbox"
                    id="vaccinationRecord"
                    name="vaccinationRecord"
                    checked={vaccinationRecord}
                    onChange={handleChange}
                    disabled={isDisabled}
                  />
                  {getImmigrationFieldValue("vaccination_option", language)}
                </Label>
              </FormGroup>
            </Col>
            <Col sm={12} className="mt-2">
              <strong>{getImmigrationFieldValue("note", language)}</strong>
            </Col>
          </Row>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default Page1;
