import store from "../store";

export const getImmigrationFieldValue = (key_name, language = "en") => {
  const i693FormKeys = store.getState().common?.immigrationFormKeys;
  if (!i693FormKeys || !i693FormKeys.length) return key_name;

  const valueObject = i693FormKeys.find((each) => each.key === key_name);
  if (!valueObject) return key_name;

  return valueObject[language] ? valueObject[language] : valueObject["en"];
};
