import {
  FRONTEMPLOYER_ERROR,
  FRONTEMPLOYER_UPDATED,
  FRONTEMPLOYER_GET_BY_ID,
  FRONTEMPLOYER_CERTIFICATE_DELETE,
  FRONTEMPLOYER_LOADING_ON_SUBMIT,
  FRONTEMPLOYER_RESET,
  FRONTEMPLOYER_LOAD_PAGE,
  FRONTEMPLOYER_LOCATION_DELETE,
  FRONTEMPLOYER_LOGO_UPDATED,
} from "actions/types";

const initialState = {
  currentEmployer: null,
  // authId: false,
  loadingEmployer: true,
  loadingListData: true,
  performDelete: false,
  error: {},
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case FRONTEMPLOYER_RESET:
      return {
        ...initialState,
        loadingEmployer: state.loadingEmployer,
      };
    case FRONTEMPLOYER_LOAD_PAGE:
      return {
        ...state,
        loadingEmployer: false,
      };
    case FRONTEMPLOYER_UPDATED:
      return {
        ...state,
        currentEmployer: payload,
        sortingParams: initialState.sortingParams,
        loadingEmployer: false,
      };
    case FRONTEMPLOYER_ERROR:
      return {
        ...state,
        error: payload,
        loadingEmployer: false,
        loadingEmployerList: false,
        loadingListData: false,
        performDelete: false,
      };
    case FRONTEMPLOYER_GET_BY_ID:
      return {
        ...state,
        currentEmployer: payload,
        loadingEmployer: false,
      };
    case FRONTEMPLOYER_CERTIFICATE_DELETE:
      return {
        ...state,
        currentEmployer: {
          ...state.currentEmployer,
        },
        sortingParams: initialState.sortingParams,
        loadingEmployer: false,
      };
    case FRONTEMPLOYER_LOADING_ON_SUBMIT:
      return {
        ...state,
        loadingEmployer: true,
      };
    case FRONTEMPLOYER_LOCATION_DELETE:
      return {
        ...state,
        currentEmployer: {
          ...state.currentEmployer,
          locations: state.currentEmployer.locations.filter(
            (location) => location._id !== payload
          ),
        },
      };

    case FRONTEMPLOYER_LOGO_UPDATED:
      return {
        ...state,
        currentEmployer: {
          ...state.currentEmployer,
          logo: payload,
        },
      };
    default:
      return state;
  }
}
