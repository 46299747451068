import React, { useState } from "react";
import { connect } from "react-redux";
import {
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Button,
  Card,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  UncontrolledAccordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
} from "reactstrap";
import Select from "react-select";
import { useDispatch } from "react-redux";

import {
  savePatientImmigration,
  getImmigrationFormKeys,
} from "actions/front/Staff/Patient";
import Alert from "views/Notifications/Alert";
import { getImmigrationFieldValue } from "utils/immgrationHelper";
import { extractNameto3 } from "utils/nodeHelper";
import moment from "moment";
import { statesUs } from "constants/statesUs";

import { setAlert } from "actions/alert";

import Page1 from "views/Front/Common/Immigration/Pages/Page1";
import Page2 from "views/Front/Common/Immigration/Pages/Page2";
import Page14 from "views/Front/Common/Immigration/Pages/Page14";

const SelectLanguageModal = ({ formData, setFormData, onClickChoose }) => {
  const handleSelect = () => (selectedOption) => {
    setFormData({ ...formData, language: selectedOption.value });
  };
  const { language } = formData;

  return (
    <Modal isOpen={language === ""}>
      <ModalHeader>
        <div className="d-inline">Please select language</div>
      </ModalHeader>
      <ModalBody>
        <FormGroup>
          <Label htmlFor="language">
            Language <span>*</span>
          </Label>
          <Select
            id="language"
            name="language"
            options={[
              {
                value: "en",
                label: "English",
              },
              {
                value: "es",
                label: "Spanish",
              },
            ]}
            onChange={handleSelect()}
          />
        </FormGroup>
      </ModalBody>
      <ModalFooter>
        <Button
          color="danger"
          size="sm"
          onClick={() => {
            onClickChoose();
          }}
        >
          Choose
        </Button>
      </ModalFooter>
    </Modal>
  );
};

const ImmgrationForm = ({
  currentEmployee,
  savePatientImmigration,
  getImmigrationFormKeys,
  history,
  currentImmigration,
}) => {
  const dispatch = useDispatch();
  const [saving, setSaving] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const [formUpdated, setFormUpdated] = React.useState(false);

  const [formData1, setFormData1] = React.useState({
    //Page 1
    language: "",
    physicianName: "",
    familyName: "",
    givenName: "",
    middleName: "",
    physicianAddress: "",
    physicianApartment: false,
    physicianStreet: false,
    physicianFloor: false,
    physicianNumber: "",
    physicianCity: "",
    physicianState: "",
    physicianZipCode: "",
    physicianProvince: "",
    physicianPostalCode: "",
    physicianCountry: "",
    gender: "",
    dob: "",
    cityOfBirth: "",
    countryOfBirth: "",
    alienNumber: "",
    uscisNumber: "",
    vaccinationRecord: false,
  });
  const [formData2, setFormData2] = React.useState({
    //Page 2
    aNumber: "",
    daytimePhone: "",
    mobilePhone: "",
    email: "",
    signatureDate: moment().format("MM/DD/YYYY"),
    interpreterFamilyName: "",
    interpreterGivenName: "",
    interpreterBusinessName: "",
    interpreterDaytimePhone: "",
    interpreterMobilePhone: "",
    interpreterEmail: "",
  });

  const [formData14, setFormData14] = React.useState({
    //Page 14
    additionalInformation: [
      {
        page_number: "",
        part_number: "",
        item_number: "",
        description: "",
      },
      {
        page_number: "",
        part_number: "",
        item_number: "",
        description: "",
      },
      {
        page_number: "",
        part_number: "",
        item_number: "",
        description: "",
      },
    ],
  });

  React.useEffect(() => {
    if (!currentImmigration) return;

    const {
      language,
      physicianName,
      physicianAddress,
      physicianApartment,
      physicianStreet,
      physicianFloor,
      physicianNumber,
      physicianCity,
      physicianState,
      physicianZipCode,
      physicianProvince,
      physicianPostalCode,
      physicianCountry,
      cityOfBirth,
      countryOfBirth,
      alienNumber,
      uscisNumber,
      vaccinationRecord,

      //Page 2
      aNumber,
      daytimePhone,
      mobilePhone,
      interpreterFamilyName,
      interpreterGivenName,
      interpreterBusinessName,
      interpreterDaytimePhone,
      interpreterMobilePhone,
      interpreterEmail,

      //Page 14
      additionalInformation,
    } = currentImmigration;

    const data1 = {
      language,
      physicianName,
      physicianAddress,
      physicianApartment,
      physicianStreet,
      physicianFloor,
      physicianNumber,
      physicianCity,
      physicianState,
      physicianZipCode,
      physicianProvince,
      physicianPostalCode,
      physicianCountry,
      cityOfBirth,
      countryOfBirth,
      alienNumber,
      uscisNumber,
      vaccinationRecord,
    };

    const data2 = {
      aNumber,
      daytimePhone,
      mobilePhone,
      interpreterFamilyName,
      interpreterGivenName,
      interpreterBusinessName,
      interpreterDaytimePhone,
      interpreterMobilePhone,
      interpreterEmail,
    };

    const data14 = {
      additionalInformation,
    };

    setFormData1({ ...formData1, ...data1 });
    setFormData2({ ...formData2, ...data2 });
    setFormData14({ ...formData14, ...data14 });
  }, [currentImmigration]);

  const { language } = formData1;
  const { additionalInformation } = formData14;

  const onClickChoose = () => {
    if (!language) {
      dispatch(setAlert("Please select language", "danger"));
      return;
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const preparedData = {
      ...formData1,
      ...formData2,
      ...formData14,
    };

    const submitData = {};

    for (let i in preparedData) {
      if (
        preparedData[i] === "" ||
        preparedData[i] === null ||
        preparedData[i] === undefined
      )
        continue;
      submitData[i] = preparedData[i];
    }

    setSaving(true);

    savePatientImmigration(
      currentEmployee._id,
      currentImmigration?._id,
      submitData,
      history
    ).then((res) => {
      setSaving(false);
      if (res?.status) {
        setFormUpdated(true);
      }
    });
  };

  const onChangeAdditionalInformation = (e, index) => {
    const { name, value } = e.target;

    const selectedAdditionalInformation = additionalInformation[index];

    selectedAdditionalInformation[name] = value;

    const newAdditionalInformation = additionalInformation.map((item, i) => {
      if (i === index) {
        return selectedAdditionalInformation;
      }
      return item;
    });

    setFormData14({
      ...formData14,
      additionalInformation: newAdditionalInformation,
    });
  };

  React.useEffect(() => {
    if (!language || !currentEmployee) return;
    setLoading(true);

    getImmigrationFormKeys(currentEmployee._id, language).then((res) => {
      setLoading(false);
    });
  }, [language]);

  React.useEffect(() => {
    if (!currentEmployee) return;

    const { firstName, lastName, middleName } = extractNameto3(
      currentEmployee?.user?.name
    );

    setFormData1((form) => ({
      ...form,
      familyName: lastName,
      givenName: firstName,
      middleName: middleName,
      gender: currentEmployee?.user?.gender,
      dob: moment(currentEmployee?.user?.dob).format("YYYY-MM-DD"),
      email: currentEmployee?.user?.email,
    }));
  }, [currentEmployee]);

  const handleClose = () => {
    window.close();
  };

  React.useEffect(() => {
    // Create a link element
    const link = document.createElement("link");
    link.rel = "stylesheet";
    link.href = "/assets/lab/css/immigration.css"; // Replace with your CSS file path
    link.id = "dynamic-immigration-stylesheet"; // Optional: Add an ID for easier reference

    // Append the link element to the document head
    document.head.appendChild(link);

    // Cleanup function to remove the link element on unmount
    return () => {
      document.head.removeChild(link);
    };
  }, []);

  return !language ? (
    <SelectLanguageModal
      onClickChoose={onClickChoose}
      formData={formData1}
      setFormData={setFormData1}
      history={history}
    />
  ) : (
    <div>
      {formUpdated ? (
        <div>
          <Alert />

          <div className="d-flex justify-content-center">
            <Button onClick={handleClose}>Close Tab</Button>
          </div>
        </div>
      ) : (
        <Row className="immigration-form">
          <Col sm="12">
            <div className="page-header d-flex justify-content-center">
              <div className="round-button-position">
                <span className="h4">Complete Patient Immigration</span>
              </div>
            </div>

            <Card>
              <CardBody>
                <Form onSubmit={handleSubmit}>
                  <UncontrolledAccordion
                    defaultOpen={["1", "2", "14"]}
                    stayOpen
                    className="immigration-accordian"
                  >
                    <AccordionItem>
                      <AccordionHeader targetId="1">
                        <b>Page 1</b>
                      </AccordionHeader>
                      <AccordionBody accordionId="1">
                        <Page1
                          formData1={formData1}
                          setFormData1={setFormData1}
                        />
                      </AccordionBody>
                    </AccordionItem>

                    <AccordionItem>
                      <AccordionHeader targetId="2">
                        <b>Page 2</b>
                      </AccordionHeader>
                      <AccordionBody accordionId="2">
                        <Page2
                          formData1={formData1}
                          formData2={formData2}
                          setFormData2={setFormData2}
                          signaturePreview={""}
                        />
                      </AccordionBody>
                    </AccordionItem>

                    <AccordionItem>
                      <AccordionHeader targetId="14">
                        <b>Page 14</b>
                      </AccordionHeader>
                      <AccordionBody accordionId="14">
                        <Page14
                          formData1={formData1}
                          formData2={formData2}
                          formData14={formData14}
                          onChangeAdditionalInformation={
                            onChangeAdditionalInformation
                          }
                        />
                      </AccordionBody>
                    </AccordionItem>
                  </UncontrolledAccordion>

                  <br />
                  <div className="d-flex float-end">
                    <Button
                      color="primary"
                      type="submit"
                      className="mr-2"
                      disabled={saving}
                    >
                      {saving ? "Saving..." : "Submit"}
                    </Button>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  errorList: state.errors,
  currentImmigration: state.staffOrderPatient.currentImmigration,
});

export default connect(mapStateToProps, {
  savePatientImmigration,
  getImmigrationFormKeys,
})(ImmgrationForm);
