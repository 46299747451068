//alert constants
export const SET_ALERT = "SET_ALERT";
export const REMOVE_ALERT = "REMOVE_ALERT";
export const URL_CHANGE = "URL_CHANGE";

//reset constant
export const RESET = "RESET";

// Common constants
export const UPDATE_BUSINESS_TYPES = "UPDATE_BUSINESS_TYPES";
export const UPDATE_BUSINESS_CATEGORIES = "UPDATE_BUSINESS_CATEGORIES";
export const UPDATE_ORG_SHORT_INFO = "UPDATE_ORG_SHORT_INFO";
export const FRONTEMPLOYEE_LOADING_ON_SUBMIT =
  "FRONTEMPLOYEE_LOADING_ON_SUBMIT";
export const FRONTEMPLOYEE_GET_BY_ID = "FRONTEMPLOYEE_GET_BY_ID";
export const FRONTEMPLOYEE_ERROR = "FRONTEMPLOYEE_ERROR";

export const FRONTEMPLOYEE_IMMIGRATION_UPDATED =
  "FRONTEMPLOYEE_IMMIGRATION_UPDATED";
export const FRONTEMPLOYEE_IMMIGRATION_DATA_UPDATED =
  "  FRONTEMPLOYEE_IMMIGRATION_DATA_UPDATED";
export const FRONTEMPLOYEE_IMMEGRATION_FORM_KEYS_UPDATED =
  "  FRONTEMPLOYEE_IMMEGRATION_FORM_KEYS_UPDATED";

//loading constant
export const LOAD_PAGE = "LOAD_PAGE";
export const LOAD_AUTH_PAGE = "LOAD_AUTH_PAGE";
export const INITIAL_LOADING = "INITIAL_LOADING";
export const LOADING_ON_SUBMIT = "LOADING_ON_SUBMIT";
export const LOADING_ON_CMS_SUBMIT = "LOADING_ON_CMS_SUBMIT";
export const LOADING_ON_EMAIL_SUBMIT = "LOADING_ON_EMAIL_SUBMIT";
export const LOADING_ON_INQUIRY_SUBMIT = "LOADING_ON_INQUIRY_SUBMIT";
export const LOADING_ON_SETTINGS_SUBMIT = "LOADING_ON_SETTINGS_SUBMIT";
export const LOADING_ON_PROFILE_SUBMIT = "LOADING_ON_PROFILE_SUBMIT";
export const LOADING_ON_USER_SUBMIT = "LOADING_ON_USER_SUBMIT";
export const LOADING_ON_CHANGING_PASSWORD = "LOADING_ON_CHANGING_PASSWORD";
export const LOADING_ON_CITY_SUBMIT = "LOADING_ON_CITY_SUBMIT";
export const LOADING_ON_LOCALITY_SUBMIT = "LOADING_ON_LOCALITY_SUBMIT";
export const LOADING_ON_STAFF_SUBMIT = "LOADING_ON_STAFF_SUBMIT";
export const LOADING_ON_PHYSICIAN_SUBMIT = "LOADING_ON_PHYSICIAN_SUBMIT";
export const LOADING_ON_PRODUCT_SUBMIT = "LOADING_ON_PRODUCT_SUBMIT";
export const LOADING_ON_GROUP_TEST_SUBMIT = "LOADING_ON_GROUP_TEST_SUBMIT";
export const LOADING_ON_HABIT_SUBMIT = "LOADING_ON_SUBMIT_SUBMIT";
export const LOADING_ON_SAMPLE_TYPE_SUBMIT = "LOADING_ON_SAMPLE_TYPE_SUBMIT";
export const LOADING_ON_LAB_SUBMIT = "LOADING_ON_LAB_SUBMIT";
export const LOADING_ON_SPECIALITY_SUBMIT = "LOADING_ON_SPECIALITY_SUBMIT";
export const LOADING_ON_DOCTOR_SUBMIT = "LOADING_ON_DOCTOR_SUBMIT";
export const LOADING_ON_CUSTOMER_SUBMIT = "LOADING_ON_CUSTOMER_SUBMIT";
export const LOADING_ON_PHLEBOTOMIST_SUBMIT = "LOADING_ON_PHLEBOTOMIST_SUBMIT";
export const LOADING_ON_LOGIN_SUBMIT = "LOADING_ON_LOGIN_SUBMIT";
export const LOADING_ON_MARK_PAID = "LOADING_ON_MARK_PAID";
export const LOADING_LAB_MAX_OUTSTANDING = "LOADING_LAB_MAX_OUTSTANDING";
export const LOADING_DOCTOR_MAX_OUTSTANDING = "LOADING_DOCTOR_MAX_OUTSTANDING";
export const LOADING_PHLEBOTOMIST_MAX_OUTSTANDING =
  "LOADING_PHLEBOTOMIST_MAX_OUTSTANDING";
export const LOADING_ON_ARTICLE_SUBMIT = "LOADING_ON_ARTICLE_SUBMIT";
export const LOADING_ON_EMAIL_VERIFICATION = "LOADING_ON_EMAIL_VERIFICATION";
export const LOADING_ON_COUPON_SUBMIT = "LOADING_ON_COUPON_SUBMIT";
export const LOADING_ON_ORDER_SUBMIT = "LOADING_ON_ORDER_SUBMIT";
export const LOADING_ON_CUSTOMER_ADDRESS_SUBMIT =
  "LOADING_ON_CUSTOMER_ADDRESS_SUBMIT";
export const LOADING_ON_CUSTOMER_PATIENT_SUBMIT =
  "LOADING_ON_CUSTOMER_PATIENT_SUBMIT";
export const OTP_SENT_SUCCESS = "OTP_SENT_SUCCESS";
export const LOGIN_OTP_SENT_SUCCESS = "LOGIN_OTP_SENT_SUCCESS";
export const ENABLE_RESEND_OTP_OPTION = "ENABLE_RESEND_OTP_OPTION";

export const LOADING_CMS_LIST_DATA = "LOADING_CMS_LIST_DATA";
export const LOADING_EMAIL_LIST_DATA = "LOADING_EMAIL_LIST_DATA";
export const LOADING_INQUIRY_LIST_DATA = "LOADING_INQUIRY_LIST_DATA";
export const LOADING_CITY_LIST_DATA = "LOADING_CITY_LIST_DATA";
export const LOADING_LOCALITY_LIST_DATA = "LOADING_LOCALITY_LIST_DATA";
export const LOADING_STAFF_LIST_DATA = "LOADING_STAFF_LIST_DATA";
export const LOADING_PHYSICIAN_LIST_DATA = "LOADING_PHYSICIAN_LIST_DATA";
export const LOADING_LAB_LIST_DATA = "LOADING_LAB_LIST_DATA";
export const LOADING_PRODUCT_LIST_DATA = "LOADING_PRODUCT_LIST_DATA";
export const LOADING_SPECIALITY_LIST_DATA = "LOADING_SPECIALITY_LIST_DATA";
export const LOADING_HABIT_LIST_DATA = "LOADING_HABIT_LIST_DATA";
export const LOADING_GROUP_TEST_LIST_DATA = "LOADING_GROUP_TEST_LIST_DATA";
export const LOADING_SAMPLE_TYPE_LIST_DATA = "LOADING_SAMPLE_TYPE_LIST_DATA";
export const LOADING_DOCTOR_LIST_DATA = "LOADING_DOCTOR_LIST_DATA";
export const LOADING_CUSTOMER_LIST_DATA = "LOADING_CUSTOMER_LIST_DATA";
export const LOADING_LAB_REVIEW_LIST_DATA = "LOADING_LAB_REVIEW_LIST_DATA";
export const LOADING_LAB_PAYMENT_LIST_DATA = "LOADING_LAB_PAYMENT_LIST_DATA";
export const LOADING_DOCTOR_PAYMENT_LIST_DATA =
  "LOADING_DOCTOR_PAYMENT_LIST_DATA";
export const LOADING_PHLEBOTOMIST_REVIEW_LIST_DATA =
  "LOADING_PHLEBOTOMIST_REVIEW_LIST_DATA";
export const LOADING_PHLEBOTOMIST_PAYMENT_LIST_DATA =
  "LOADING_PHLEBOTOMIST_PAYMENT_LIST_DATA";
export const LOADING_ARTICLE_LIST_DATA = "LOADING_ARTICLE_LIST_DATA";
export const LOADING_COUPON_LIST_DATA = "LOADING_COUPON_LIST_DATA";
export const LOADING_ORDER_LIST_DATA = "LOADING_ORDER_LIST_DATA";

export const LOADING_ON_PASSWORD_RESET = "LOADING_ON_PASSWORD_RESET";
export const LOADING_TEST_CATEGORIES = "LOADING_TEST_CATEGORIES";
export const LOADING_CERTIFICATE_CATEGORIES = "LOADING_CERTIFICATE_CATEGORIES";

//errors constnats
export const SET_ERRORS_LIST = "SET_ERRORS_LIST";
export const REMOVE_ERRORS = "REMOVE_ERRORS";

//login/logout/register, auth, profile constnats
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";

export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";

export const LOGOUT = "LOGOUT";

export const ACCOUNT_DELETED = "ACCOUNT_DELETED";

export const USER_LOADED = "USER_LOADED";
export const USER_AUTHENTICATED = "USER_AUTHENTICATED";
export const AUTH_TOKEN_REFRESH = "AUTH_TOKEN_REFRESH";

export const GET_PROFILE = "GET_PROFILE";
export const CLEAR_PROFILE = "CLEAR_PROFILE";
export const GET_PROFILES = "GET_PROFILES";
export const UPDATE_PROFILE = "UPDATE_PROFILE";
export const PROFILE_ERROR = "PROFILE_ERROR";

export const AUTH_ERROR = "AUTH_ERROR";
export const AUTH_LOAD_PAGE = "AUTH_LOAD_PAGE";

export const PENDING_ACTION_LOADED = "PENDING_ACTION_LOADED";

// Verification Constants
export const VERIFICATION_LINK_SUCCESS = "VERIFICATION_LINK_SUCCESS";
export const VERIFICATION_LINK_FAIL = "VERIFICATION_LINK_FAIL";

// Articles Constants
export const ARTICLE_CREATED = "ARTICLE_CREATED";
export const ARTICLE_ERROR = "ARTICLE_ERROR";
export const DELETE_ARTICLE = "DELETE_ARTICLE";
export const ARTICLE_UPDATED = "ARTICLE_UPDATED";
export const ARTICLE_LIST_UPDATED = "ARTICLE_LIST_UPDATED";
export const GET_ARTICLE_BY_ID = "GET_ARTICLE_BY_ID";
export const CHANGE_ARTICLE_STATUS = "CHANGE_ARTICLE_STATUS";
export const ARTICLE_SEARCH_PARAMATERS_UPDATE =
  "ARTICLE_SEARCH_PARAMATERS_UPDATE";

// Customer Address Constants
export const CUSTOMER_ADDRESS_CREATED = "CUSTOMER_ADDRESS_CREATED";
export const CUSTOMER_ADDRESS_ERROR = "CUSTOMER_ADDRESS_ERROR";
export const CUSTOMER_ADDRESS_LIST_UPDATED = "CUSTOMER_ADDRESS_LIST_UPDATED";
export const CUSTOMER_ADDRESS_SEARCH_PARAMATERS_UPDATE =
  "CUSTOMER_ADDRESS_SEARCH_PARAMATERS_UPDATE";

// Customer Patient Constants
export const CUSTOMER_PATIENT_CREATED = "CUSTOMER_PATIENT_CREATED";
export const CUSTOMER_PATIENT_ERROR = "CUSTOMER_PATIENT_ERROR";
export const CUSTOMER_PATIENT_LIST_UPDATED = "CUSTOMER_PATIENT_LIST_UPDATED";
export const CUSTOMER_PATIENT_SEARCH_PARAMATERS_UPDATE =
  "CUSTOMER_PATIENT_SEARCH_PARAMATERS_UPDATE";

//Users constants
export const USER_CREATED = "USER_CREATED";
export const USER_ERROR = "USER_CREATE_ERROR";
export const DELETE_USER = "DELETE_USER";
export const USER_UPDATED = "USER_UPDATED";
export const USER_LIST_UPDATED = "USER_LIST_UPDATED";
export const GET_USER_BY_ID = "GET_USER_BY_ID";
export const CHANGE_USER_STATUS = "CHANGE_USER_STATUS";
export const USER_SEARCH_PARAMATERS_UPDATE = "USER_SEARCH_PARAMATERS_UPDATE";
export const USER_PROFILE_PASSWORD_ERROR = "USER_PROFILE_PASSWORD_ERROR";
export const USER_PROFILE_PASSWORD_UPDATED = "USER_PROFILE_PASSWORD_UPDATED";
export const USER_PROFILE_LOADED = "USER_PROFILE_LOADED";

//Email Template Constants
export const EMAIL_TEMPLATE_CREATED = "EMAIL_TEMPLATE_CREATED";
export const EMAIL_TEMPLATE_ERROR = "EMAIL_TEMPLATE_ERROR";
export const DELETE_EMAIL_TEMPLATE = "DELETE_EMAIL_TEMPLATE";
export const EMAIL_TEMPLATE_UPDATED = "EMAIL_TEMPLATE_UPDATED";
export const EMAIL_TEMPLATE_LIST_UPDATED = "EMAIL_TEMPLATE_LIST_UPDATED";
export const GET_EMAIL_TEMPLATE_BY_ID = "GET_EMAIL_TEMPLATE_BY_ID";
export const CHANGE_EMAIL_TEMPLATE_STATUS = "CHANGE_EMAIL_TEMPLATE_STATUS";
export const EMAIL_TEMPLATE_SEARCH_PARAMATERS_UPDATE =
  "EMAIL_TEMPLATE_SEARCH_PARAMATERS_UPDATE";

//CMS Constants
export const CMS_CREATED = "CMS_CREATED";
export const CMS_ERROR = "CMS_ERROR";
export const DELETE_CMS = "DELETE_CMS";
export const CMS_UPDATED = "CMS_UPDATED";
export const CMS_LIST_UPDATED = "CMS_LIST_UPDATED";
export const GET_CMS_BY_ID = "GET_CMS_BY_ID";
export const CHANGE_CMS_STATUS = "CHANGE_CMS_STATUS";
export const CMS_SEARCH_PARAMATERS_UPDATE = "CMS_SEARCH_PARAMATERS_UPDATE";

//Coupon Constants
export const COUPON_CREATED = "COUPON_CREATED";
export const COUPON_ERROR = "COUPON_ERROR";
export const COUPON_DELETE = "COUPON_DELETE";
export const COUPON_UPDATED = "COUPON_UPDATED";
export const COUPON_LIST_UPDATED = "COUPON_LIST_UPDATED";
export const GET_COUPON_BY_ID = "GET_COUPON_BY_ID";
export const CHANGE_COUPON_STATUS = "CHANGE_COUPON_STATUS";
export const COUPON_SEARCH_PARAMATERS_UPDATE =
  "COUPON_SEARCH_PARAMATERS_UPDATE";

//Inquiry Constants
export const INQUIRY_CREATED = "INQUIRY_CREATED";
export const INQUIRY_ERROR = "INQUIRY_ERROR";
export const DELETE_INQUIRY = "DELETE_INQUIRY";
export const INQUIRY_UPDATED = "INQUIRY_UPDATED";
export const INQUIRY_LIST_UPDATED = "INQUIRY_LIST_UPDATED";
export const GET_INQUIRY_BY_ID = "GET_INQUIRY_BY_ID";
export const CHANGE_INQUIRY_STATUS = "CHANGE_INQUIRY_STATUS";
export const INQUIRY_SEARCH_PARAMATERS_UPDATE =
  "INQUIRY_SEARCH_PARAMATERS_UPDATE";

//City and Locality Constants
export const CITY_CREATED = "CITY_CREATED";
export const CITY_ERROR = "CITY_ERROR";
export const CITY_DELETE = "CITY_DELETE";
export const CITY_UPDATED = "CITY_UPDATED";
export const CITY_LIST_UPDATED = "CITY_LIST_UPDATED";
export const GET_CITY_BY_ID = "GET_CITY_BY_ID";
export const CHANGE_CITY_STATUS = "CHANGE_CITY_STATUS";
export const CITY_SEARCH_PARAMATERS_UPDATE = "CITY_SEARCH_PARAMATERS_UPDATE";
export const LOCALITY_CREATED = "LOCALITY_CREATED";
export const LOCALITY_ERROR = "LOCALITY_ERROR";
export const LOCALITY_DELETE = "LOCALITY_DELETE";
export const LOCALITY_UPDATED = "LOCALITY_UPDATED";
export const LOCALITY_LIST_UPDATED = "LOCALITY_LIST_UPDATED";
export const GET_LOCALITY_BY_ID = "GET_LOCALITY_BY_ID";
export const CHANGE_LOCALITY_STATUS = "CHANGE_LOCALITY_STATUS";
export const LOCALITY_SEARCH_PARAMATERS_UPDATE =
  "LOCALITY_SEARCH_PARAMATERS_UPDATE";

//Admin Staff Constants
export const STAFF_CREATED = "STAFF_CREATED";
export const STAFF_ERROR = "STAFF_ERROR";
export const STAFF_DELETE = "STAFF_DELETE";
export const STAFF_UPDATED = "STAFF_UPDATED";
export const STAFF_LIST_UPDATED = "STAFF_LIST_UPDATED";
export const GET_STAFF_BY_ID = "GET_STAFF_BY_ID";
export const CHANGE_STAFF_STATUS = "CHANGE_STAFF_STATUS";
export const STAFF_SEARCH_PARAMATERS_UPDATE = "STAFF_SEARCH_PARAMATERS_UPDATE";

//Organization Physician Constants
export const PHYSICIAN_CREATED = "PHYSICIAN_CREATED";
export const PHYSICIAN_ERROR = "PHYSICIAN_ERROR";
export const PHYSICIAN_DELETE = "PHYSICIAN_DELETE";
export const PHYSICIAN_UPDATED = "PHYSICIAN_UPDATED";
export const PHYSICIAN_LIST_UPDATED = "PHYSICIAN_LIST_UPDATED";
export const GET_PHYSICIAN_BY_ID = "GET_PHYSICIAN_BY_ID";
export const CHANGE_PHYSICIAN_STATUS = "CHANGE_PHYSICIAN_STATUS";
export const PHYSICIAN_SEARCH_PARAMATERS_UPDATE =
  "PHYSICIAN_SEARCH_PARAMATERS_UPDATE";

// Lab Tests Constants Start //

// Test Constants
export const PRODUCT_CREATED = "PRODUCT_CREATED";
export const PRODUCT_ERROR = "PRODUCT_ERROR";
export const PRODUCT_DELETE = "PRODUCT_DELETE";
export const PRODUCT_UPDATED = "PRODUCT_UPDATED";
export const PRODUCT_CSV_ADDED = "PRODUCT_CSV_ADDED";
export const PRODUCT_LIST_UPDATED = "PRODUCT_LIST_UPDATED";
export const GET_PRODUCT_BY_ID = "GET_PRODUCT_BY_ID";
export const CHANGE_PRODUCT_STATUS = "CHANGE_PRODUCT_STATUS";
export const PRODUCT_SEARCH_PARAMATERS_UPDATE =
  "PRODUCT_SEARCH_PARAMATERS_UPDATE";

// Group Test Constants
export const GROUP_TEST_CREATED = "GROUP_TEST_CREATED";
export const GROUP_TEST_ERROR = "GROUP_TEST_ERROR";
export const GROUP_TEST_DELETE = "GROUP_TEST_DELETE";
export const GROUP_TEST_UPDATED = "GROUP_TEST_UPDATED";
export const GROUP_TEST_LIST_UPDATED = "GROUP_TEST_LIST_UPDATED";
export const GET_GROUP_TEST_BY_ID = "GET_GROUP_TEST_BY_ID";
export const CHANGE_GROUP_TEST_STATUS = "CHANGE_GROUP_TEST_STATUS";
export const GROUP_TEST_SEARCH_PARAMATERS_UPDATE =
  "GROUP_TEST_SEARCH_PARAMATERS_UPDATE";

// Habit Test Constants
export const HABIT_CREATED = "HABIT_CREATED";
export const HABIT_ERROR = "HABIT_ERROR";
export const HABIT_DELETE = "HABIT_DELETE";
export const HABIT_UPDATED = "HABIT_UPDATED";
export const HABIT_LIST_UPDATED = "HABIT_LIST_UPDATED";
export const GET_HABIT_BY_ID = "GET_HABIT_BY_ID";
export const CHANGE_HABIT_STATUS = "CHANGE_HABIT_STATUS";
export const HABIT_SEARCH_PARAMATERS_UPDATE = "HABIT_SEARCH_PARAMATERS_UPDATE";

// Sample Type Constants
export const SAMPLE_TYPE_CREATED = "SAMPLE_TYPE_CREATED";
export const SAMPLE_TYPE_ERROR = "SAMPLE_TYPE_ERROR";
export const SAMPLE_TYPE_DELETE = "SAMPLE_TYPE_DELETE";
export const SAMPLE_TYPE_UPDATED = "SAMPLE_TYPE_UPDATED";
export const SAMPLE_TYPE_LIST_UPDATED = "SAMPLE_TYPE_LIST_UPDATED";
export const GET_SAMPLE_TYPE_BY_ID = "GET_SAMPLE_TYPE_BY_ID";
export const CHANGE_SAMPLE_TYPE_STATUS = "CHANGE_SAMPLE_TYPE_STATUS";
export const SAMPLE_TYPE_SEARCH_PARAMATERS_UPDATE =
  "SAMPLE_TYPE_SEARCH_PARAMATERS_UPDATE";

// Lab Tests Constants End //

// Lab Constants
export const LAB_CREATED = "LAB_CREATED";
export const LAB_ERROR = "LAB_ERROR";
export const LAB_DELETE = "LAB_DELETE";
export const LAB_UPDATED = "LAB_UPDATED";
export const LAB_LIST_UPDATED = "LAB_LIST_UPDATED";
export const GET_LAB_BY_ID = "GET_LAB_BY_ID";
export const CHANGE_LAB_STATUS = "CHANGE_LAB_STATUS";
export const CHANGE_LAB_LOCATION = "CHANGE_LAB_LOCATION";
export const LAB_SEARCH_PARAMATERS_UPDATE = "LAB_SEARCH_PARAMATERS_UPDATE";
export const LAB_CERTIFICATE_DELETE = "LAB_CERTIFICATE_DELETE";

// Lab Tests Provided Constants
export const LAB_TESTS_PROVIDED_CREATED = "LAB_TESTS_PROVIDED_CREATED";
export const LAB_TESTS_PROVIDED_IMPORTED = "LAB_TESTS_PROVIDED_IMPORTED";
export const LAB_TESTS_PROVIDED_ERROR = "LAB_TESTS_PROVIDED_ERROR";
export const CHANGE_LAB_TESTS_PROVIDED_STATUS =
  "CHANGE_LAB_TESTS_PROVIDED_STATUS";
export const LAB_TESTS_PROVIDED_LIST_UPDATED =
  "LAB_TESTS_PROVIDED_LIST_UPDATED";
export const LAB_TESTS_PROVIDED_SEARCH_PARAMATERS_UPDATE =
  "LAB_TESTS_PROVIDED_SEARCH_PARAMATERS_UPDATE";
export const LOADING_ON_LAB_TESTS_PROVIDED_SUBMIT =
  "LOADING_ON_LAB_TESTS_PROVIDED_SUBMIT";
export const LOADING_LAB_TESTS_PROVIDED_LIST_DATA =
  "LOADING_LAB_TESTS_PROVIDED_LIST_DATA";

// Lab Groups Provided Constants
export const LAB_GROUPS_PROVIDED_IMPORTED = "LAB_GROUPS_PROVIDED_IMPORTED";
export const LAB_GROUPS_PROVIDED_ERROR = "LAB_GROUPS_PROVIDED_ERROR";
export const LAB_GROUPS_PROVIDED_LIST_UPDATED =
  "LAB_GROUPS_PROVIDED_LIST_UPDATED";
export const LAB_GROUPS_PROVIDED_SEARCH_PARAMATERS_UPDATE =
  "LAB_GROUPS_PROVIDED_SEARCH_PARAMATERS_UPDATE";
export const LOADING_ON_LAB_GROUPS_PROVIDED_SUBMIT =
  "LOADING_ON_LAB_GROUPS_PROVIDED_SUBMIT";
export const LOADING_LAB_GROUPS_PROVIDED_LIST_DATA =
  "LOADING_LAB_GROUPS_PROVIDED_LIST_DATA";

//Tests And Groups Constants
export const TEST_AND_GROUP_EXPORTED = "TEST_AND_GROUP_EXPORTED";
export const TEST_AND_GROUP_ERROR = "TEST_AND_GROUP_ERROR";
export const TEST_AND_GROUP_LIST_UPDATED = "TEST_AND_GROUP_LIST_UPDATED";
export const TEST_AND_GROUP_SEARCH_PARAMATERS_UPDATE =
  "TEST_AND_GROUP_SEARCH_PARAMATERS_UPDATE";
export const LOADING_TEST_AND_GROUP_LIST_DATA =
  "LOADING_TEST_AND_GROUP_LIST_DATA";
export const LOADING_TEST_AND_GROUP_ON_EXPORT =
  "LOADING_TEST_AND_GROUP_ON_EXPORT";

// Lab Review Constants
export const LAB_REVIEW_ERROR = "LAB_REVIEW_ERROR";
export const LAB_REVIEW_LIST_UPDATED = "LAB_REVIEW_LIST_UPDATED";
export const LAB_REVIEW_SEARCH_PARAMATERS_UPDATE =
  "LAB_REVIEW_SEARCH_PARAMATERS_UPDATE";
export const CHANGE_LAB_REVIEW_STATUS = "CHANGE_LAB_REVIEW_STATUS";

// Lab Payment Constants
export const LAB_MAX_OUTSTANDING = "LAB_MAX_OUTSTANDING";
export const LAB_PAYMENT_CREATED = "LAB_PAYMENT_CREATED";
export const LAB_PAYMENT_ERROR = "LAB_PAYMENT_ERROR";
export const LAB_PAYMENT_LIST_UPDATED = "LAB_PAYMENT_LIST_UPDATED";
export const LAB_PAYMENT_SEARCH_PARAMATERS_UPDATE =
  "LAB_PAYMENT_SEARCH_PARAMATERS_UPDATE";
export const CHANGE_LAB_PAYMENT_STATUS = "CHANGE_LAB_PAYMENT_STATUS";

// Ledger Constants
export const LEDGER_ERROR = "LEDGER_ERROR";
export const LEDGER_LIST_UPDATED = "LEDGER_LIST_UPDATED";
export const LEDGER_SEARCH_PARAMATERS_UPDATE =
  "LEDGER_SEARCH_PARAMATERS_UPDATE";
export const LOADING_LEDGER_LIST_DATA = "LOADING_LEDGER_LIST_DATA";

// Doctors Constants Start //

// Speciality Constants
export const SPECIALITY_CREATED = "SPECIALITY_CREATED";
export const SPECIALITY_ERROR = "SPECIALITY_ERROR";
export const SPECIALITY_DELETE = "SPECIALITY_DELETE";
export const SPECIALITY_UPDATED = "SPECIALITY_UPDATED";
export const SPECIALITY_LIST_UPDATED = "SPECIALITY_LIST_UPDATED";
export const GET_SPECIALITY_BY_ID = "GET_SPECIALITY_BY_ID";
export const CHANGE_SPECIALITY_STATUS = "CHANGE_SPECIALITY_STATUS";
export const SPECIALITY_SEARCH_PARAMATERS_UPDATE =
  "SPECIALITY_SEARCH_PARAMATERS_UPDATE";

// Doctors Constants
export const DOCTOR_CREATED = "DOCTOR_CREATED";
export const DOCTOR_ERROR = "DOCTOR_ERROR";
export const DOCTOR_DELETE = "DOCTOR_DELETE";
export const DOCTOR_UPDATED = "DOCTOR_UPDATED";
export const DOCTOR_LIST_UPDATED = "DOCTOR_LIST_UPDATED";
export const GET_DOCTOR_BY_ID = "GET_DOCTOR_BY_ID";
export const CHANGE_DOCTOR_STATUS = "CHANGE_DOCTOR_STATUS";
export const DOCTOR_SEARCH_PARAMATERS_UPDATE =
  "DOCTOR_SEARCH_PARAMATERS_UPDATE";

// Doctor Payment Constants

export const DOCTOR_MAX_OUTSTANDING = "DOCTOR_MAX_OUTSTANDING";
export const DOCTOR_PAYMENT_CREATED = "DOCTOR_PAYMENT_CREATED";
export const DOCTOR_PAYMENT_ERROR = "DOCTOR_PAYMENT_ERROR";
export const DOCTOR_PAYMENT_LIST_UPDATED = "DOCTOR_PAYMENT_LIST_UPDATED";
export const DOCTOR_PAYMENT_SEARCH_PARAMATERS_UPDATE =
  "DOCTOR_PAYMENT_SEARCH_PARAMATERS_UPDATE";
export const CHANGE_DOCTOR_PAYMENT_STATUS = "CHANGE_DOCTOR_PAYMENT_STATUS";

// Doctors Constants End //

// Customer Constants
export const CUSTOMER_CREATED = "CUSTOMER_CREATED";
export const CUSTOMER_ERROR = "CUSTOMER_ERROR";
export const CUSTOMER_DELETE = "CUSTOMER_DELETE";
export const CUSTOMER_UPDATED = "CUSTOMER_UPDATED";
export const CUSTOMER_LIST_UPDATED = "CUSTOMER_LIST_UPDATED";
export const GET_CUSTOMER_BY_ID = "GET_CUSTOMER_BY_ID";
export const CHANGE_CUSTOMER_STATUS = "CHANGE_CUSTOMER_STATUS";
export const CUSTOMER_SEARCH_PARAMATERS_UPDATE =
  "CUSTOMER_SEARCH_PARAMATERS_UPDATE";

// Phlebotomist Start Constants //

export const PHLEBOTOMIST_CREATED = "PHLEBOTOMIST_CREATED";
export const PHLEBOTOMIST_ERROR = "PHLEBOTOMIST_ERROR";
export const PHLEBOTOMIST_DELETE = "PHLEBOTOMIST_DELETE";
export const PHLEBOTOMIST_UPDATED = "PHLEBOTOMIST_UPDATED";
export const PHLEBOTOMIST_LIST_UPDATED = "PHLEBOTOMIST_LIST_UPDATED";
export const GET_PHLEBOTOMIST_BY_ID = "GET_PHLEBOTOMIST_BY_ID";
export const CHANGE_PHLEBOTOMIST_STATUS = "CHANGE_PHLEBOTOMIST_STATUS";
export const PHLEBOTOMIST_SEARCH_PARAMATERS_UPDATE =
  "PHLEBOTOMIST_SEARCH_PARAMATERS_UPDATE";
export const PHLEBOTOMIST_CERTIFICATE_DELETE =
  "PHLEBOTOMIST_CERTIFICATE_DELETE";
export const LOADING_PHLEBOTOMIST_LIST_DATA = "LOADING_PHLEBOTOMIST_LIST_DATA";

// Phlebotomist Review Constants
export const PHLEBOTOMIST_REVIEW_ERROR = "PHLEBOTOMIST_REVIEW_ERROR";
export const PHLEBOTOMIST_REVIEW_LIST_UPDATED =
  "PHLEBOTOMIST_REVIEW_LIST_UPDATED";
export const PHLEBOTOMIST_REVIEW_SEARCH_PARAMATERS_UPDATE =
  "PHLEBOTOMIST_REVIEW_SEARCH_PARAMATERS_UPDATE";
export const CHANGE_PHLEBOTOMIST_REVIEW_STATUS =
  "CHANGE_PHLEBOTOMIST_REVIEW_STATUS";

// Phlebotomist Payment Constants
export const PHLEBOTOMIST_MAX_OUTSTANDING = "PHLEBOTOMIST_MAX_OUTSTANDING";
export const PHLEBOTOMIST_PAYMENT_CREATED = "PHLEBOTOMIST_PAYMENT_CREATED";
export const PHLEBOTOMIST_PAYMENT_ERROR = "PHLEBOTOMIST_PAYMENT_ERROR";
export const PHLEBOTOMIST_PAYMENT_LIST_UPDATED =
  "PHLEBOTOMIST_PAYMENT_LIST_UPDATED";
export const PHLEBOTOMIST_PAYMENT_SEARCH_PARAMATERS_UPDATE =
  "PHLEBOTOMIST_PAYMENT_SEARCH_PARAMATERS_UPDATE";
export const CHANGE_PHLEBOTOMIST_PAYMENT_STATUS = "CHANGE_LAB_PAYMENT_STATUS";

// Phlebotomist End Constants //

// Orders Constants //
export const ORDER_CREATED = "PHLEBOTOMIST_CREATED";
export const ORDER_ERROR = "ORDER_ERROR";
export const ORDER_DELETE = "ORDER_DELETE";
export const ORDER_UPDATED = "ORDER_UPDATED";
export const ORDER_CANCEL = "ORDER_CANCEL";
export const ORDER_HOLD = "ORDER_HOLD";
export const ORDER_LIST_UPDATED = "ORDER_LIST_UPDATED";
export const ORDER_PRESCRIPTION_APPROVED = "ORDER_PRESCRIPTION_APPROVED";
export const GET_ORDER_BY_ID = "GET_ORDER_BY_ID";
export const CHANGE_ORDER_STATUS = "CHANGE_ORDER_STATUS";
export const ORDER_SEARCH_PARAMATERS_UPDATE = "ORDER_SEARCH_PARAMATERS_UPDATE";
export const ORDER_CERTIFICATE_DELETE = "ORDER_CERTIFICATE_DELETE";

//Log constant

export const LOG_ERROR = "LOG_ERROR";
export const LOG_LIST_UPDATED = "LOG_LIST_UPDATED";
export const GET_LOG_BY_ID = "GET_LOG_BY_ID";
export const LOG_SEARCH_PARAMATERS_UPDATE = "LOG_SEARCH_PARAMATERS_UPDATE";
export const LOADING_LOG_LIST_DATA = "LOADING_LOG_LIST_DATA";
export const LOADING_ON_LOG_SUBMIT = "LOADING_ON_LOG_SUBMIT";

// Setting Constants
export const SETTING_ERROR = "SETTING_ERROR";
export const SETTING_UPDATED = "SETTING_UPDATED";
export const GET_SETTING = "GET_SETTING";

// Profile Constants
export const USER_PROFILE_ERROR = "USER_PROFILE_ERROR";
export const USER_PROFILE_UPDATED = "USER_PROFILE_UPDATED";
export const INITIAL_MODAL_STATE = "INITIAL_MODAL_STATE";

// Forgot Password Constants
export const RESET_LINK_SUCCESS = "RESET_LINK_SUCCESS";
export const RESET_LINK_FAIL = "RESET_LINK_FAIL";

// Front Lab Phlebotomits
export const FRONTLAB_PHLEBOTOMIST_RESET = "FRONTLAB_PHLEBOTOMIST_RESET";
export const FRONTLAB_PHLEBOTOMIST_LOAD_PAGE =
  "FRONTLAB_PHLEBOTOMIST_LOAD_PAGE";
export const FRONTLAB_PHLEBOTOMIST_LIST_LOADING =
  "FRONTLAB_PHLEBOTOMIST_LIST_LOADING";
export const FRONTLAB_PHLEBOTOMIST_ERROR = "FRONTLAB_PHLEBOTOMIST_ERROR";
export const FRONTLAB_PHLEBOTOMIST_LOADING_ON_SUBMIT =
  "FRONTLAB_PHLEBOTOMIST_LOADING_ON_SUBMIT";
export const FRONTLAB_PHLEBOTOMIST_CREATED = "FRONTLAB_PHLEBOTOMIST_CREATED";
export const FRONTLAB_PHLEBOTOMIST_CHANGE_STATUS =
  "FRONTLAB_PHLEBOTOMIST_CHANGE_STATUS";
export const FRONTLAB_PHLEBOTOMIST_GET_BY_ID =
  "FRONTLAB_PHLEBOTOMIST_GET_BY_ID";
export const FRONTLAB_PHLEBOTOMIST_UPDATED = "FRONTLAB_PHLEBOTOMIST_UPDATED";
export const FRONTLAB_PHLEBOTOMIST_LIST_UPDATED =
  "FRONTLAB_PHLEBOTOMIST_LIST_UPDATED";
export const FRONTLAB_PHLEBOTOMIST_QUALIFICATION_DELETE =
  "FRONTLAB_PHLEBOTOMIST_QUALIFICATION_DELETE";
export const FRONTLAB_PHLEBOTOMIST_DELETE = "FRONTLAB_PHLEBOTOMIST_DELETE";
export const FRONTLAB_PHLEBOTOMIST_SEARCH_PARAMATERS_UPDATE =
  "FRONTLAB_PHLEBOTOMIST_SEARCH_PARAMATERS_UPDATE";

//Front Physician Test-order list
export const FRONTPHYSICIAN_TESTORDER_LIST_LOADING =
  "FRONTPHYSICIAN_TESTORDER_LIST_LOADING";
export const FRONTPHYSICIAN_TESTORDER_RESET = "FRONTPHYSICIAN_TESTORDER_RESET";
export const FRONTPHYSICIAN_TESTORDER_LOAD_PAGE =
  "FRONTPHYSICIAN_TESTORDER_LOAD_PAGE";
export const FRONTPHYSICIAN_TESTORDER_ERROR = "FRONTPHYSICIAN_TESTORDER_ERROR";
export const FRONTPHYSICIAN_TESTORDER_LOADING_ON_SUBMIT =
  "FRONTPHYSICIAN_TESTORDER_LOADING_ON_SUBMIT";
export const FRONTPHYSICIAN_TESTORDER__CREATED =
  "FRONTPHYSICIAN_TESTORDER__CREATED";
export const FRONTPHYSICIAN_TESTORDER_EMPLOYERS_LIST =
  "FRONTPHYSICIAN_TESTORDER_EMPLOYERS_LIST";
export const FRONTPHYSICIAN_TESTORDER_EMPLOYEES_LIST =
  "FRONTPHYSICIAN_TESTORDER_EMPLOYEES_LIST";
export const FRONTPHYSICIAN_TESTORDER_CLIENTS_LIST =
  "FRONTPHYSICIAN_TESTORDER_CLIENTS_LIST";
export const FRONTPHYSICIAN_TESTORDER_LOCATIONS_LIST =
  "FRONTPHYSICIAN_TESTORDER_LOCATIONS_LIST";
export const FRONTPHYSICIAN_TESTORDER_GET_BY_ID =
  "FRONTPHYSICIAN_TESTORDER_GET_BY_ID";
export const FRONTPHYSICIAN_TESTORDER_UPDATED =
  "FRONTPHYSICIAN_TESTORDER_UPDATED";
export const FRONTPHYSICIAN_TESTORDER_LIST_UPDATED =
  "FRONTPHYSICIAN_TESTORDER_LIST_UPDATED";
export const FRONTPHYSICIAN_TESTORDER_SEARCH_PARAMATERS_UPDATE =
  "FRONTPHYSICIAN_TESTORDER_SEARCH_PARAMATERS_UPDATE";
export const FRONTPHYSICIAN_TESTORDER_COMMENT_SUBMIT =
  "FRONTPHYSICIAN_TESTORDER_COMMENT_SUBMIT";
export const FRONTPHYSICIAN_TESTORDER_COMMENT_COMPLETE =
  "FRONTPHYSICIAN_TESTORDER_COMMENT_COMPLETE";
export const FRONTPHYSICIAN_TESTORDER_COMMENT_UPDATE =
  "FRONTPHYSICIAN_TESTORDER_COMMENT_UPDATE";
export const FRONTPHYSICIAN_TESTORDER_PROVIDER_OBSERVATION_SUBMIT =
  "FRONTPHYSICIAN_TESTORDER_PROVIDER_OBSERVATION_SUBMIT";
export const FRONTPHYSICIAN_TESTORDER_PROVIDER_OBSERVATION_COMPLETE =
  "FRONTPHYSICIAN_TESTORDER_PROVIDER_OBSERVATION_COMPLETE";
export const FRONTPHYSICIAN_TESTORDER_MRO_OBSERVATION_UPDATE =
  "FRONTPHYSICIAN_TESTORDER_MRO_OBSERVATION_UPDATE";
export const FRONTPHYSICIAN_TESTORDER_PROVIDER_OBSERVATION_UPDATE =
  "FRONTPHYSICIAN_TESTORDER_PROVIDER_OBSERVATION_UPDATE";
export const FRONTPHYSICIAN_TESTORDER_RESULT_UPLOADED_SINGLE =
  "FRONTPHYSICIAN_TESTORDER_RESULT_UPLOADED_SINGLE";
export const FRONTPHYSICIAN_EMPLOYEE_CONSENT_LOADING =
  "FRONTPHYSICIAN_EMPLOYEE_CONSENT_LOADING";
export const FRONTPHYSICIAN_EMPLOYEE_CONSENT_TEXT =
  "FRONTPHYSICIAN_EMPLOYEE_CONSENT_TEXT";
export const FRONTPHYSICIAN_EMPLOYEE_CONSENT_ERROR =
  "FRONTPHYSICIAN_EMPLOYEE_CONSENT_ERROR";
export const FRONTPHYSICIAN_EMPLOYEE_ERROR = "FRONTPHYSICIAN_EMPLOYEE_ERROR";
export const FRONTPHYSICIAN_EMPLOYEE_CONSENT_ON_SUBMIT =
  "FRONTPHYSICIAN_EMPLOYEE_CONSENT_ON_SUBMIT";
export const FRONTPHYSICIAN_EMPLOYEE_CONSENT_SUBMITED =
  "FRONTPHYSICIAN_EMPLOYEE_CONSENT_SUBMITED";
export const FRONTPHYSICIAN_EMPLOYER_BUSINESS_TYPES_UPDATED =
  "FRONTPHYSICIAN_EMPLOYER_BUSINESS_TYPES_UPDATED";
export const FRONTPHYSICIAN_TESTORDER_STATES_LIST =
  "FRONTPHYSICIAN_TESTORDER_STATES_LIST";

export const FRONTLAB_TESTORDER_LIST_LOADING =
  "FRONTLAB_TESTORDER_LIST_LOADING";
export const FRONTLAB_TESTORDER_RESET = "FRONTLAB_TESTORDER_RESET";
export const FRONTLAB_TESTORDER_LOAD_PAGE = "FRONTLAB_TESTORDER_LOAD_PAGE";
export const FRONTLAB_TESTORDER_ERROR = "FRONTLAB_TESTORDER_ERROR";
export const FRONTLAB_TESTORDER_CHANGE_STATUS =
  "FRONTLAB_TESTORDER_CHANGE_STATUS";
export const FRONTLAB_TESTORDER_CREATED = "FRONTLAB_TESTORDER_CREATED";
export const FRONTLAB_TESTORDER_LOADING_ON_SUBMIT =
  "FRONTLAB_TESTORDER_LOADING_ON_SUBMIT";
export const FRONTLAB_TESTORDER_GET_BY_ID = "FRONTLAB_TESTORDER_GET_BY_ID";
export const FRONTLAB_TESTORDER_UPDATED = "FRONTLAB_TESTORDER_UPDATED";
export const FRONTLAB_TESTORDER_LIST_UPDATED =
  "FRONTLAB_TESTORDER_LIST_UPDATED";
export const FRONTLAB_TESTORDER_QUALIFICATION_DELETE =
  "FRONTLAB_TESTORDER_QUALIFICATION_DELETE";
export const FRONTLAB_TESTORDER_DELETE = "FRONTLAB_TESTORDER_DELETE";
export const FRONTLAB_TESTORDER_SEARCH_PARAMATERS_UPDATE =
  "FRONTLAB_TESTORDER_SEARCH_PARAMATERS_UPDATE";
export const FRONTLAB_TESTORDER_UPLOADED = "FRONTLAB_TESTORDER_UPLOADED";
export const FRONTLAB_TESTORDER_RESULT_UPLOADED =
  "FRONTLAB_TESTORDER_RESULT_UPLOADED";
export const FRONTLAB_TESTORDER_PATIENT_DATA =
  "FRONTLAB_TESTORDER_PATIENT_DATA";
export const FRONTLAB_TESTORDER_RESULT_UPLOADED_SINGLE =
  "FRONTLAB_TESTORDER_RESULT_UPLOADED_SINGLE";
export const FRONTLAB_TESTORDER_RESULT_REMOVED =
  "FRONTLAB_TESTORDER_RESULT_REMOVED";

//Test-order
export const FRONTEMPLOYER_TESTORDER_LIST_LOADING =
  "FRONTEMPLOYER_TESTORDER_LIST_LOADING";
export const FRONTEMPLOYER_TESTORDER_RESET = "FRONTEMPLOYER_TESTORDER_RESET";
export const FRONTEMPLOYER_TESTORDER_LOAD_PAGE =
  "FRONTEMPLOYER_TESTORDER_LOAD_PAGE";
export const FRONTEMPLOYER_TESTORDER_ERROR = "FRONTEMPLOYER_TESTORDER_ERROR";
export const FRONTEMPLOYER_TESTORDER_CHANGE_STATUS =
  "FRONTEMPLOYER_TESTORDER_CHANGE_STATUS";
export const FRONTEMPLOYER_TESTORDER_CREATED =
  "FRONTEMPLOYER_TESTORDER_CREATED";
export const FRONTEMPLOYER_TESTORDER_LOADING_ON_SUBMIT =
  "FRONTEMPLOYER_TESTORDER_LOADING_ON_SUBMIT";
export const FRONTEMPLOYER_TESTORDER_GET_BY_ID =
  "FRONTEMPLOYER_TESTORDER_GET_BY_ID";
export const FRONTEMPLOYER_TESTORDER_UPDATED =
  "FRONTEMPLOYER_TESTORDER_UPDATED";
export const FRONTEMPLOYER_TESTORDER_LIST_UPDATED =
  "FRONTEMPLOYER_TESTORDER_LIST_UPDATED";
export const FRONTEMPLOYER_TESTORDER_QUALIFICATION_DELETE =
  "FRONTEMPLOYER_TESTORDER_QUALIFICATION_DELETE";
export const FRONTEMPLOYER_TESTORDER_DELETE = "FRONTEMPLOYER_TESTORDER_DELETE";
export const FRONTEMPLOYER_TESTORDER_SEARCH_PARAMATERS_UPDATE =
  "FRONTEMPLOYER_TESTORDER_SEARCH_PARAMATERS_UPDATE";
export const FRONTEMPLOYER_TESTORDER_UPLOADED =
  "FRONTEMPLOYER_TESTORDER_UPLOADED";
export const FRONTEMPLOYER_TESTORDER_EMPLOYEES_LIST =
  "FRONTEMPLOYER_TESTORDER_EMPLOYEES_LIST";
export const FRONTEMPLOYER_TESTORDER_CANCEL_COMPLETE =
  "FRONTEMPLOYER_TESTORDER_CANCEL_COMPLETE";
export const FRONTEMPLOYER_TESTORDER_CANCEL_SUBMIT =
  "FRONTEMPLOYER_TESTORDER_CANCEL_SUBMIT";
export const FRONTEMPLOYER_TESTORDER_STAFF_LIST =
  "FRONTEMPLOYER_TESTORDER_STAFF_LIST";
export const FRONTEMPLOYER_TESTORDER_PHYSICIAN_LIST =
  "FRONTEMPLOYER_TESTORDER_PHYSICIAN_LIST";
export const FRONTEMPLOYER_TESTORDER_NURSE_LIST =
  "FRONTEMPLOYER_TESTORDER_NURSE_LIST";
export const FRONTEMPLOYER_TESTORDER_PHLEBOTOMIST_LIST =
  "FRONTEMPLOYER_TESTORDER_PHLEBOTOMIST_LIST";
export const FRONTEMPLOYER_TESTORDER_PATIENT_FORM_UPLOADED =
  "FRONTEMPLOYER_TESTORDER_PATIENT_FORM_UPLOADED";

// Front Employer-> Employee

export const FRONTEMPLOYER_EMPLOYEE_LIST_LOADING =
  "FRONTEMPLOYER_EMPLOYEE_LIST_LOADING";
export const FRONTEMPLOYER_EMPLOYEE_RESET = "FRONTEMPLOYER_EMPLOYEE_RESET";
export const FRONTEMPLOYER_EMPLOYEE_LOAD_PAGE =
  "FRONTEMPLOYER_EMPLOYEE_LOAD_PAGE";
export const FRONTEMPLOYER_EMPLOYEE_ERROR = "FRONTEMPLOYER_EMPLOYEE_ERROR";
export const FRONTEMPLOYER_EMPLOYEE_CHANGE_STATUS =
  "FRONTEMPLOYER_EMPLOYEE_CHANGE_STATUS";
export const FRONTEMPLOYER_EMPLOYEE_CREATED = "FRONTEMPLOYER_EMPLOYEE_CREATED";
export const FRONTEMPLOYER_EMPLOYEE_LOADING_ON_SUBMIT =
  "FRONTEMPLOYER_EMPLOYEE_LOADING_ON_SUBMIT";
export const FRONTEMPLOYER_EMPLOYEE_GET_BY_ID =
  "FRONTEMPLOYER_EMPLOYEE_GET_BY_ID";
export const FRONTEMPLOYER_EMPLOYEE_UPDATED = "FRONTEMPLOYER_EMPLOYEE_UPDATED";
export const FRONTEMPLOYER_EMPLOYEE_LIST_UPDATED =
  "FRONTEMPLOYER_EMPLOYEE_LIST_UPDATED";
export const FRONTEMPLOYER_EMPLOYEE_QUALIFICATION_DELETE =
  "FRONTEMPLOYER_EMPLOYEE_QUALIFICATION_DELETE";
export const FRONTEMPLOYER_EMPLOYEE_DELETE = "FRONTEMPLOYER_EMPLOYEE_DELETE";
export const FRONTEMPLOYER_EMPLOYEE_SEARCH_PARAMATERS_UPDATE =
  "FRONTEMPLOYER_EMPLOYEE_SEARCH_PARAMATERS_UPDATE";
export const FRONTEMPLOYER_EMPLOYEE_UPLOADED =
  "FRONTEMPLOYER_EMPLOYEE_UPLOADED";
export const FRONTEMPLOYER_EMPLOYEE_INSURANCE_ON_SUBMIT =
  "FRONTEMPLOYER_EMPLOYEE_INSURANCE_ON_SUBMIT";
export const FRONTEMPLOYER_EMPLOYEE_INSURANCE_UPDATED =
  "FRONTEMPLOYER_EMPLOYEE_INSURANCE_UPDATED";
//Front Employer
export const FRONTEMPLOYER_ERROR = "FRONTEMPLOYER_ERROR";
export const FRONTEMPLOYER_UPDATED = "FRONTEMPLOYER_UPDATED";
export const FRONTEMPLOYER_GET_BY_ID = "FRONTEMPLOYER_GET_BY_ID";
export const FRONTEMPLOYER_CERTIFICATE_DELETE =
  "FRONTEMPLOYER_CERTIFICATE_DELETE";
export const FRONTEMPLOYER_LOADING_ON_SUBMIT =
  "FRONTEMPLOYER_LOADING_ON_SUBMIT";
export const FRONTEMPLOYER_RESET = "FRONTEMPLOYER_RESET";
export const FRONTEMPLOYER_LOAD_PAGE = "FRONTEMPLOYER_LOAD_PAGE";
export const FRONTEMPLOYER_ORGANIZATION_LIST =
  "FRONTEMPLOYER_ORGANIZATION_LIST";
export const FRONTEMPLOYER_LOADING_ORGANIZATION_LIST_DATA =
  "FRONTEMPLOYER_LOADING_ORGANIZATION_LIST_DATA";

// Front Lab Test & Prices
export const FRONTLAB_TEST_PRICES_ERROR = "FRONTLAB_TEST_PRICES_ERROR";
export const FRONTLAB_TEST_PRICES_RESET = "FRONTLAB_TEST_PRICES_RESET";
export const FRONTLAB_TEST_PRICES_LIST_LOADING =
  "FRONTLAB_TEST_PRICES_LIST_LOADING";
export const FRONTLAB_TEST_PRICES_SEARCH_PARAMATERS_UPDATE =
  "FRONTLAB_TEST_PRICES_SEARCH_PARAMATERS_UPDATE";
export const FRONTLAB_TEST_PRICES_LIST_UPDATED =
  "FRONTLAB_TEST_PRICES_LIST_UPDATED";
export const FRONTLAB_TEST_PRICES_STATUS_UPDATE =
  "FRONTLAB_TEST_PRICES_STATUS_UPDATE";
export const FRONTLAB_TEST_GROUP_LIST_UPDATE =
  "FRONTLAB_TEST_GROUP_LIST_UPDATE";
export const FRONTLAB_TEST_PRICES_SUBMIT_LOADING =
  "FRONTLAB_TEST_PRICES_SUBMIT_LOADING";
export const FRONTLAB_TEST_PRICES_CREATED = "FRONTLAB_TEST_PRICES_CREATED";
export const FRONTLAB_TEST_PRICES_EXPORT_LOADING =
  "FRONTLAB_TEST_PRICES_EXPORT_LOADING";
export const FRONTLAB_TEST_PRICES_EXPORTED = "FRONTLAB_TEST_PRICES_EXPORTED";
export const FRONTLAB_TEST_PRICES_IMPORTED = "FRONTLAB_TEST_PRICES_IMPORTED";

// Test Categories Constants
export const TEST_CATEGORIES_ERROR = "TEST_CATEGORIES_ERROR";
export const TEST_CATEGORIES_LIST_UPDATED = "TEST_CATEGORIES_LIST_UPDATED";

// Certificate Categories Constants
export const CERTIFICATE_CATEGORIES_ERROR = "CERTIFICATE_CATEGORIES_ERROR";
export const CERTIFICATE_CATEGORIES_LIST_UPDATED =
  "CERTIFICATE_CATEGORIES_LIST_UPDATED";

//Front Lab Collection Center
export const FRONTLAB_COLLECTION_CENTER_RESET =
  "FRONTLAB_COLLECTION_CENTER_RESET";
export const FRONTLAB_COLLECTION_CENTER_LOAD_PAGE =
  "FRONTLAB_COLLECTION_CENTER_LOAD_PAGE";
export const FRONTLAB_COLLECTION_CENTER_LOADING_ON_SUBMIT =
  "FRONTLAB_COLLECTION_CENTER_LOADING_ON_SUBMIT";
export const FRONTLAB_COLLECTION_CENTER_CREATE =
  "FRONTLAB_COLLECTION_CENTER_CREATE";
export const FRONTLAB_COLLECTION_CENTER_ERROR =
  "FRONTLAB_COLLECTION_CENTER_ERROR";
export const FRONTLAB_COLLECTION_CENTER_LIST_LOADING =
  "FRONTLAB_COLLECTION_CENTER_LIST_LOADING";
export const FRONTLAB_COLLECTION_CENTER_LIST_UPDATED =
  "FRONTLAB_COLLECTION_CENTER_LIST_UPDATED";
export const FRONTLAB_COLLECTION_CENTER_DELETE =
  "FRONTLAB_COLLECTION_CENTER_DELETE";
export const FRONTLAB_COLLECTION_CENTER_GET_BY_ID =
  "FRONTLAB_COLLECTION_CENTER_GET_BY_ID";
export const FRONTLAB_COLLECTION_CENTER_UPDATED =
  "FRONTLAB_COLLECTION_CENTER_UPDATED";
export const FRONTLAB_COLLECTION_CENTER_SEARCH_PARAMATERS_UPDATE =
  "FRONTLAB_COLLECTION_CENTER_SEARCH_PARAMATERS_UPDATE";

//Admin Lab Collection Center
export const COLLECTION_CENTER_RESET = "COLLECTION_CENTER_RESET";
export const COLLECTION_CENTER_LOAD_PAGE = "COLLECTION_CENTER_LOAD_PAGE";
export const COLLECTION_CENTER_LOADING_ON_SUBMIT =
  "COLLECTION_CENTER_LOADING_ON_SUBMIT";
export const COLLECTION_CENTER_CREATE = "COLLECTION_CENTER_CREATE";
export const COLLECTION_CENTER_ERROR = "COLLECTION_CENTER_ERROR";
export const COLLECTION_CENTER_LIST_LOADING = "COLLECTION_CENTER_LIST_LOADING";
export const COLLECTION_CENTER_LIST_UPDATED = "COLLECTION_CENTER_LIST_UPDATED";
export const COLLECTION_CENTER_DELETE = "COLLECTION_CENTER_DELETE";
export const COLLECTION_CENTER_GET_BY_ID = "COLLECTION_CENTER_GET_BY_ID";
export const COLLECTION_CENTER_UPDATED = "COLLECTION_CENTER_UPDATED";
export const COLLECTION_CENTER_SEARCH_PARAMATERS_UPDATE =
  "COLLECTION_CENTER_SEARCH_PARAMATERS_UPDATE";

//Front Lab
export const FRONTLAB_ERROR = "FRONTLAB_ERROR";
export const FRONTLAB_UPDATED = "FRONTLAB_UPDATED";
export const FRONTLAB_GET_BY_ID = "FRONTLAB_GET_BY_ID";
export const FRONTLAB_CERTIFICATE_DELETE = "FRONTLAB_CERTIFICATE_DELETE";
export const FRONTLAB_LOADING_ON_SUBMIT = "FRONTLAB_LOADING_ON_SUBMIT";
export const FRONTLAB_RESET = "FRONTLAB_RESET";
export const FRONTLAB_LOAD_PAGE = "FRONTLAB_LOAD_PAGE";
export const FRONTLAB_GET_HL7 = "FRONTLAB_GET_HL7";
export const FRONTLAB_GET_HL7_PROCEDURE_CODES =
  "FRONTLAB_GET_HL7_PROCEDURE_CODES";

//Front Physician
export const FRONTPHYSICIAN_ERROR = "FRONTPHYSICIAN_ERROR";
export const FRONTPHYSICIAN_UPDATED = "FRONTPHYSICIAN_UPDATED";
export const FRONTPHYSICIAN_GET_BY_ID = "FRONTPHYSICIAN_GET_BY_ID";
export const FRONTPHYSICIAN_LOADING_ON_SUBMIT =
  "FRONTPHYSICIAN_LOADING_ON_SUBMIT";
export const FRONTPHYSICIAN_RESET = "FRONTPHYSICIAN_RESET";
export const FRONTPHYSICIAN_LOAD_PAGE = "FRONTPHYSICIAN_LOAD_PAGE";
export const FRONTPHYSICIAN_DASHBOARD_GET_ORDERS_COUNT =
  "FRONTPHYSICIAN_DASHBOARD_GET_ORDERS_COUNT";

export const FRONTMRO_DASHBOARD_GET_DRUGTESTS_COUNT =
  "FRONTMRO_DASHBOARD_GET_DRUGTESTS_COUNT";

//Front Staff
export const FRONTSTAFF_ERROR = "FRONTSTAFF_ERROR";
export const FRONTSTAFF_UPDATED = "FRONTSTAFF_UPDATED";
export const FRONTSTAFF_GET_BY_ID = "FRONTSTAFF_GET_BY_ID";
export const FRONTSTAFF_LOADING_ON_SUBMIT = "FRONTSTAFF_LOADING_ON_SUBMIT";
export const FRONTSTAFF_RESET = "FRONTSTAFF_RESET";
export const FRONTSTAFF_LOAD_PAGE = "FRONTSTAFF_LOAD_PAGE";

export const FRONTSTAFF_TESTORDER_ERROR = "FRONTSTAFF_TESTORDER_ERROR";
export const FRONTSTAFF_TESTORDER_GET_BY_ID = "FRONTSTAFF_TESTORDER_GET_BY_ID";
export const FRONTSTAFF_TESTORDER_LIST_LOADING =
  "FRONTSTAFF_TESTORDER_LIST_LOADING";
export const FRONTSTAFF_TESTORDER_LIST_UPDATED =
  "FRONTSTAFF_TESTORDER_LIST_UPDATED";
export const FRONTSTAFF_TESTORDER_LOADING_ON_SUBMIT =
  "FRONTSTAFF_TESTORDER_LOADING_ON_SUBMIT";
export const FRONTSTAFF_TESTORDER_LOAD_PAGE = "FRONTSTAFF_TESTORDER_LOAD_PAGE";
export const FRONTSTAFF_TESTORDER_RESET = "FRONTSTAFF_TESTORDER_RESET";
export const FRONTSTAFF_TESTORDER_SEARCH_PARAMATERS_UPDATE =
  "FRONTSTAFF_TESTORDER_SEARCH_PARAMATERS_UPDATE";
export const FRONTSTAFF_TESTORDER_UPDATED = "FRONTSTAFF_TESTORDER_UPDATED";

//Front Lab Reviews
export const FRONTLAB_REVIEW_ERROR = "FRONTLAB_REVIEW_ERROR";
export const FRONTLAB_REVIEW_LIST_UPDATED = "FRONTLAB_REVIEW_LIST_UPDATED";
export const FRONTLAB_REVIEW_SEARCH_PARAMATERS_UPDATE =
  "FRONTLAB_REVIEW_SEARCH_PARAMATERS_UPDATE";
export const FRONTLAB_REVIEW_CHANGE_STATUS = "FRONTLAB_REVIEW_CHANGE_STATUS";
export const FRONTLAB_REVIEW_LIST_LOADING = "FRONTLAB_REVIEW_LIST_LOADING";

//Front Lab phlebotomist Reviews
export const FRONTLAB_PHLEBOTOMIST_REVIEW_ERROR =
  "FRONTLAB_PHLEBOTOMIST_REVIEW_ERROR";
export const FRONTLAB_PHLEBOTOMIST_REVIEW_LIST_UPDATED =
  "FRONTLAB_PHLEBOTOMIST_REVIEW_LIST_UPDATED";
export const FRONTLAB_PHLEBOTOMIST_REVIEW_SEARCH_PARAMATERS_UPDATE =
  "FRONTLAB_PHLEBOTOMIST_REVIEW_SEARCH_PARAMATERS_UPDATE";
export const FRONTLAB_PHLEBOTOMIST_REVIEW_CHANGE_STATUS =
  "FRONTLAB_PHLEBOTOMIST_REVIEW_CHANGE_STATUS";
export const FRONTLAB_PHLEBOTOMIST_REVIEW_LIST_LOADING =
  "FRONTLAB_PHLEBOTOMIST_REVIEW_LIST_LOADING";

export const LOCATION_CREATED = "LOCATION_CREATED";
export const LOCATION_ERROR = "LOCATION_ERROR";
export const LOCATION_DELETE = "LOCATION_DELETE";
export const LOCATION_UPDATED = "LOCATION_UPDATED";
export const LOCATION_LIST_UPDATED = "LOCATION_LIST_UPDATED";
export const GET_LOCATION_BY_ID = "GET_LOCATION_BY_ID";
export const LOCATION_SEARCH_PARAMATERS_UPDATE =
  "LOCATION_SEARCH_PARAMATERS_UPDATE";
export const CHANGE_LOCATION_STATUS = "CHANGE_LOCATION_STATUS";
export const LOADING_ON_LOCATION_SUBMIT = "LOADING_ON_LOCATION_SUBMIT";
export const LOADING_LOCATION_LIST_DATA = "LOADING_LOCATION_LIST_DATA";
export const LOCATION_LIST_FOR_ORG = "LOCATION_LIST_FOR_ORG";

export const CLIENT_CREATED = "CLIENT_CREATED";
export const CLIENT_UPDATED = "CLIENT_UPDATED";
export const CLIENT_DELETE = "CLIENT_DELETE";
export const CLIENT_ERROR = "CLIENT_ERROR";
export const GET_CLIENT_BY_ID = "GET_CLIENT_BY_ID";
export const CLIENT_LIST_UPDATED = "CLIENT_LIST_UPDATED";
export const LOADING_CLIENT_LIST_DATA = "LOADING_CLIENT_LIST_DATA";
export const CHANGE_CLIENT_STATUS = "CHANGE_CLIENT_STATUS";
export const LOADING_ON_CLIENT_SUBMIT = "LOADING_ON_CLIENT_SUBMIT";

// Admin Employers
export const EMPLOYER_CREATED = "EMPLOYER_CREATED";
export const EMPLOYER_ERROR = "EMPLOYER_ERROR";
export const EMPLOYER_LIST_UPDATED = "EMPLOYER_LIST_UPDATED";
export const EMPLOYER_SEARCH_PARAMATERS_UPDATE =
  "EMPLOYER_SEARCH_PARAMATERS_UPDATE";
export const LOADING_EMPLOYER_LIST_DATA = "LOADING_EMPLOYER_LIST_DATA";
export const EMPLOYER_DELETE = "EMPLOYER_DELETE";
export const EMPLOYER_UPDATED = "EMPLOYER_UPDATED";
export const GET_EMPLOYER_BY_ID = "GET_EMPLOYER_BY_ID";
export const CHANGE_EMPLOYER_STATUS = "CHANGE_EMPLOYER_STATUS";
export const LOADING_ON_EMPLOYER_SUBMIT = "LOADING_ON_EMPLOYER_SUBMIT";

//Employer Recurring orders
export const FRONTEMPLOYER_RECURRING_ORDER_LIST_LOADING =
  "FRONTEMPLOYER_RECURRING_ORDER_LIST_LOADING";
export const FRONTEMPLOYER_RECURRING_ORDER_RESET =
  "FRONTEMPLOYER_RECURRING_ORDER_RESET";
export const FRONTEMPLOYER_RECURRING_ORDER_LOAD_PAGE =
  "FRONTEMPLOYER_RECURRING_ORDER_LOAD_PAGE";
export const FRONTEMPLOYER_RECURRING_ORDER_ERROR =
  "FRONTEMPLOYER_RECURRING_ORDER_ERROR";
export const FRONTEMPLOYER_RECURRING_ORDER_CHANGE_STATUS =
  "FRONTEMPLOYER_RECURRING_ORDER_CHANGE_STATUS";
export const FRONTEMPLOYER_RECURRING_ORDER_CREATED =
  "FRONTEMPLOYER_RECURRING_ORDER_CREATED";
export const FRONTEMPLOYER_RECURRING_ORDER_LOADING_ON_SUBMIT =
  "FRONTEMPLOYER_RECURRING_ORDER_LOADING_ON_SUBMIT";
export const FRONTEMPLOYER_RECURRING_ORDER_GET_BY_ID =
  "FRONTEMPLOYER_RECURRING_ORDER_GET_BY_ID";
export const FRONTEMPLOYER_RECURRING_ORDER_UPDATED =
  "FRONTEMPLOYER_RECURRING_ORDER_UPDATED";
export const FRONTEMPLOYER_RECURRING_ORDER_LIST_UPDATED =
  "FRONTEMPLOYER_RECURRING_ORDER_LIST_UPDATED";
export const FRONTEMPLOYER_RECURRING_ORDER_QUALIFICATION_DELETE =
  "FRONTEMPLOYER_RECURRING_ORDER_QUALIFICATION_DELETE";
export const FRONTEMPLOYER_RECURRING_ORDER_DELETE =
  "FRONTEMPLOYER_RECURRING_ORDER_DELETE";
export const FRONTEMPLOYER_RECURRING_ORDER_SEARCH_PARAMATERS_UPDATE =
  "FRONTEMPLOYER_RECURRING_ORDER_SEARCH_PARAMATERS_UPDATE";
export const FRONTEMPLOYER_RECURRING_ORDER_UPLOADED =
  "FRONTEMPLOYER_RECURRING_ORDER_UPLOADED";
export const FRONTEMPLOYER_RECURRING_ORDER_EMPLOYEES_LIST =
  "FRONTEMPLOYER_RECURRING_ORDER_EMPLOYEES_LIST";

export const FRONTEMPLOYER_LOCATION_DELETE = "FRONTEMPLOYER_LOCATION_DELETE";
export const FRONTEMPLOYER_LOGO_UPDATED = "FRONTEMPLOYER_LOGO_UPDATED";

// Staff test order patients
export const FRONTSTAFF_EMPLOYEE_RESET = "FRONTSTAFF_EMPLOYEE_RESET";
export const FRONTSTAFF_EMPLOYEE_LOAD_PAGE = "FRONTSTAFF_EMPLOYEE_LOAD_PAGE";
export const FRONTSTAFF_EMPLOYEE_ERROR = "FRONTSTAFF_EMPLOYEE_ERROR";
export const FRONTSTAFF_EMPLOYEE_LOADING_ON_SUBMIT =
  "FRONTSTAFF_EMPLOYEE_LOADING_ON_SUBMIT";
export const FRONTSTAFF_EMPLOYEE_GET_BY_ID = "FRONTSTAFF_EMPLOYEE_GET_BY_ID";
export const FRONTSTAFF_EMPLOYEE_CREATED = "FRONTSTAFF_EMPLOYEE_CREATED";
export const FRONTSTAFF_EMPLOYEE_UPDATED = "FRONTSTAFF_EMPLOYEE_UPDATED";
export const FRONTSTAFF_EMPLOYEE_INSURANCE_ON_SUBMIT =
  "FRONTSTAFF_EMPLOYEE_INSURANCE_ON_SUBMIT";
export const FRONTSTAFF_EMPLOYEE_INSURANCE_UPDATED =
  "FRONTSTAFF_EMPLOYEE_INSURANCE_UPDATED";
export const FRONTSTAFF_EMPLOYEE_ORDER = "FRONTSTAFF_EMPLOYEE_ORDER";
export const FRONTSTAFF_EMPLOYEE_BARCODE_ON_SUBMIT =
  "FRONTSTAFF_EMPLOYEE_BARCODE_ON_SUBMIT";
export const FRONTSTAFF_EMPLOYEE_BARCODE_SAVED =
  "FRONTSTAFF_EMPLOYEE_BARCODE_SAVED";
export const FRONTSTAFF_EMPLOYEE_BARCODE_ERROR =
  "FRONTSTAFF_EMPLOYEE_BARCODE_ERROR";
export const FRONTSTAFF_EMPLOYEE_HEALTH_LOADING =
  "FRONTSTAFF_EMPLOYEE_HEALTH_LOADING";
export const FRONTSTAFF_EMPLOYEE_HEALTH_QUESTIONS =
  "FRONTSTAFF_EMPLOYEE_HEALTH_QUESTIONS";
export const FRONTSTAFF_EMPLOYEE_HEALTH_ERROR =
  "FRONTSTAFF_EMPLOYEE_HEALTH_ERROR";
export const FRONTSTAFF_EMPLOYEE_HEALTH_ON_SUBMIT =
  "FRONTSTAFF_EMPLOYEE_HEALTH_ON_SUBMIT";
export const FRONTSTAFF_EMPLOYEE_HEALTH_SUBMITED =
  "FRONTSTAFF_EMPLOYEE_HEALTH_SUBMITED";
export const FRONTSTAFF_EMPLOYEE_CONSENT_LOADING =
  "FRONTSTAFF_EMPLOYEE_CONSENT_LOADING";
export const FRONTSTAFF_EMPLOYEE_CONSENT_ERROR =
  "FRONTSTAFF_EMPLOYEE_CONSENT_ERROR";
export const FRONTSTAFF_EMPLOYEE_CONSENT_TEXT =
  "FRONTSTAFF_EMPLOYEE_CONSENT_TEXT";
export const FRONTSTAFF_EMPLOYEE_CONSENT_ON_SUBMIT =
  "FRONTSTAFF_EMPLOYEE_CONSENT_ON_SUBMIT";
export const FRONTSTAFF_EMPLOYEE_CONSENT_SUBMITED =
  "FRONTSTAFF_EMPLOYEE_CONSENT_SUBMITED";
export const FRONTSTAFF_EMPLOYEE_LOADING_DONE =
  "FRONTSTAFF_EMPLOYEE_LOADING_DONE";
export const FRONTSTAFF_EMPLOYEE_IMMIGRATION_UPDATED =
  "FRONTSTAFF_EMPLOYEE_IMMIGRATION_UPDATED";
export const FRONTSTAFF_EMPLOYEE_IMMIGRATION_DATA_UPDATED =
  "FRONTSTAFF_EMPLOYEE_IMMIGRATION_DATA_UPDATED";
export const FRONTSTAFF_EMPLOYEE_IMMEGRATION_FORM_KEYS_UPDATED =
  "FRONTSTAFF_EMPLOYEE_IMMEGRATION_FORM_KEYS_UPDATED";
export const FRONTEMPLOYER_MY_LOCATIONS_LIST =
  "FRONTEMPLOYER_MY_LOCATIONS_LIST";
export const FRONTEMPLOYER_CLIENT_LIST = "FRONTEMPLOYER_CLIENT_LIST";
export const FRONTEMPLOYER_BUSINESS_TYPES_UPDATED =
  "FRONTEMPLOYER_BUSINESS_TYPES_UPDATED";

export const STATES_LIST = "STATES_LIST";

export const UPDATE_EMPLOYMENT_TESTS_LIST = "UPDATE_EMPLOYMENT_TESTS_LIST";
export const FRONTEMPLOYER_DASHBOARD_GET_ORDERS_COUNT =
  "FRONTEMPLOYER_DASHBOARD_GET_ORDERS_COUNT";

export const FRONTEMPLOYER_ANALYTICS_GET_ORDERS_COUNT =
  "FRONTEMPLOYER_ANALYTICS_GET_ORDERS_COUNT";

export const USER_UPDATE_USER_PROFILE = "USER_UPDATE_USER_PROFILE";
export const USER_LOAD_USER_PROFILE = "USER_LOAD_USER_PROFILE";
export const USER_PROFILE_UPLOAD_ERROR = "USER_PROFILE_UPLOAD_ERROR";
export const USER_PROFILE_UPLOADING = "USER_PROFILE_UPLOADING";
