import React from "react";
import { Row, Col, FormGroup, Label, Input, Table, Button } from "reactstrap";
import { getImmigrationFieldValue } from "utils/immgrationHelper";

const Page2 = ({
  formData1,
  formData2,
  setFormData2,
  signaturePreview,
  isDisabled,
}) => {
  const { language, familyName, givenName, middleName } = formData1;
  const {
    aNumber,
    daytimePhone,
    mobilePhone,
    email,
    signatureDate,
    interpreterFamilyName,
    interpreterGivenName,
    interpreterBusinessName,
    interpreterDaytimePhone,
    interpreterMobilePhone,
    interpreterEmail,
  } = formData2;

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData2({
      ...formData2,
      [name]: type === "checkbox" ? checked : value,
    });
  };
  return (
    <React.Fragment>
      <Row>
        <Col sm={12} className="mt-3">
          <div className="table-outer">
            <Table bordered>
              <thead>
                <tr>
                  <th className="text-center">
                    {getImmigrationFieldValue("family_name", language)}
                  </th>
                  <th className="text-center">
                    {getImmigrationFieldValue("given_name", language)}
                  </th>
                  <th className="text-center">
                    {getImmigrationFieldValue("middle_name", language)}
                  </th>
                  <th className="text-center">
                    {getImmigrationFieldValue("a_number_if_any", language)}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="text-center">{familyName}</td>
                  <td className="text-center">{givenName}</td>
                  <td className="text-center">{middleName}</td>
                  <td>
                    <Input
                      type="text"
                      name="aNumber"
                      value={aNumber}
                      onChange={handleChange}
                      maxLength="9"
                      disabled={isDisabled}
                    />
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>
        </Col>
      </Row>

      <div className="immgration-form-header mb-3 p-3">
        <h4 className="">
          {getImmigrationFieldValue("part2_statement", language)}
        </h4>
      </div>
      <Row>
        <Col sm={12}>
          <div className="immgration-form-sub-header">
            {getImmigrationFieldValue("contact_info", language)}
          </div>
        </Col>

        <Col sm="6" className="d-flex">
          <b className="pr-4 d-inline-block">1.</b>

          <FormGroup className="w-100">
            <Label for="daytimePhone">
              {getImmigrationFieldValue("daytime_telephone", language)}
            </Label>
            <Input
              type="text"
              id="daytimePhone"
              name="daytimePhone"
              value={daytimePhone}
              onChange={handleChange}
              disabled={isDisabled}
            />
          </FormGroup>
        </Col>

        <Col md={6} className="d-flex">
          <b className="pr-4 d-inline-block">2.</b>
          <FormGroup className="w-100">
            <Label for="mobilePhone">
              {getImmigrationFieldValue("mobile_telephone", language)}
            </Label>
            <Input
              className="pl-3"
              type="text"
              id="mobilePhone"
              name="mobilePhone"
              value={mobilePhone}
              onChange={handleChange}
              disabled={isDisabled}
            />
          </FormGroup>
        </Col>
        <Col sm="6" className="d-flex">
          <b className="pr-4 d-inline-block">3.</b>
          <FormGroup className="w-100">
            <Label for="email">
              {getImmigrationFieldValue("email_address", language)}
            </Label>
            <Input
              className="pl-3"
              type="email"
              id="email"
              name="email"
              value={email}
              disabled
            />
          </FormGroup>
        </Col>

        <Col sm="12" className="mb-1 p-3">
          <div className="immgration-form-sub-header">
            {getImmigrationFieldValue("certification_signature", language)}
          </div>

          <p>{getImmigrationFieldValue("certification_text", language)}</p>
        </Col>

        <Col sm={12} className="mt-1">
          <strong>{getImmigrationFieldValue("do_not_sign", language)}</strong>
        </Col>

        <Col md={8} className="d-flex mt-2">
          <b className="pr-4 d-inline-block">4.</b>
          <FormGroup className="w-100">
            <Label for="signature" className="d-block mb-2">
              {getImmigrationFieldValue("applicant_signature", language)}
            </Label>

            <div>{signaturePreview}</div>
          </FormGroup>
        </Col>
        <Col md={4}>
          <FormGroup>
            <Label for="signatureDate">
              {getImmigrationFieldValue("date_signature", language)}
            </Label>
            <Input
              type="text"
              id="signatureDate"
              name="signatureDate"
              value={signatureDate}
              disabled
            />
          </FormGroup>
        </Col>
      </Row>

      <div sm="12" className="immgration-form-header mb-3 p-3">
        <h4 className="m-0 p-0">
          {getImmigrationFieldValue("part3_statement", language)}
        </h4>
      </div>

      <Row>
        <Col sm="12" className="p-3">
          <div className="immgration-form-sub-header">
            {getImmigrationFieldValue("interpreter_name", language)}
          </div>
        </Col>

        <Col md={6} className="d-flex">
          <b className="pr-4 d-inline-block">1.</b>
          <FormGroup className="w-100">
            <Label for="interpreterFamilyName">
              {getImmigrationFieldValue("interpreter_family_name", language)}
            </Label>
            <Input
              type="text"
              id="interpreterFamilyName"
              name="interpreterFamilyName"
              value={interpreterFamilyName}
              onChange={handleChange}
              disabled={isDisabled}
            />
          </FormGroup>
        </Col>
        <Col md={6}>
          <FormGroup>
            <Label for="interpreterGivenName">
              {getImmigrationFieldValue("interpreter_given_name", language)}
            </Label>
            <Input
              type="text"
              id="interpreterGivenName"
              name="interpreterGivenName"
              value={interpreterGivenName}
              onChange={handleChange}
              disabled={isDisabled}
            />
          </FormGroup>
        </Col>
        <Col md={6} className="d-flex">
          <b className="pr-4 d-inline-block">2.</b>
          <FormGroup className="w-100">
            <Label for="interpreterBusinessName">
              {getImmigrationFieldValue("interpreter_business_name", language)}
            </Label>
            <Input
              type="text"
              id="interpreterBusinessName"
              name="interpreterBusinessName"
              value={interpreterBusinessName}
              onChange={handleChange}
              disabled={isDisabled}
            />
          </FormGroup>
        </Col>

        <Col sm="12" className="p-3">
          <div className="immgration-form-sub-header">
            {getImmigrationFieldValue("interpreter_contact_info", language)}
          </div>
        </Col>

        <Col md={6} className="d-flex">
          <b className="pr-4 d-inline-block">3.</b>
          <FormGroup className="w-100">
            <Label for="interpreterDaytimePhone">
              {getImmigrationFieldValue("interpreter_daytime_phone", language)}
            </Label>
            <Input
              type="text"
              id="interpreterDaytimePhone"
              name="interpreterDaytimePhone"
              value={interpreterDaytimePhone}
              onChange={handleChange}
              disabled={isDisabled}
            />
          </FormGroup>
        </Col>
        <Col md={6} className="d-flex">
          <b className="pr-4 d-inline-block">4.</b>
          <FormGroup className="w-100">
            <Label for="interpreterMobilePhone">
              {getImmigrationFieldValue("interpreter_mobile_phone", language)}
            </Label>
            <Input
              type="text"
              id="interpreterMobilePhone"
              name="interpreterMobilePhone"
              value={interpreterMobilePhone}
              onChange={handleChange}
              disabled={isDisabled}
            />
          </FormGroup>
        </Col>
        <Col md={12} className="d-flex">
          <b className="pr-4 d-inline-block">5.</b>
          <FormGroup className="w-100">
            <Label for="interpreterEmail">
              {getImmigrationFieldValue("interpreter_email", language)}
            </Label>
            <Input
              type="text"
              id="interpreterEmail"
              name="interpreterEmail"
              value={interpreterEmail}
              onChange={handleChange}
              disabled={isDisabled}
            />
          </FormGroup>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default Page2;
