import React from "react";
import { Container, Row, Col } from "reactstrap";
import LoginPageGrapic from "assets/Login-Page-Grapic.png";
import "./Login/login.css";
import LogoSvg from "assets/iLabDx-Logo.png";
import Img from "react-image";

const LoginLayout = ({ children }) => {
  return (
    <div className="page page-h login-page-bg">
      <div className="page-content z-index-10">
        <Container>
          <Row>
            <div className="login-form">
              <div className="left-bx">
                <Row>
                  <Col md="12">
                    <div className="text-center mb-5">
                      <Img
                        alt="iConnectUs Logo"
                        src={LogoSvg}
                        width="170"
                        className=""
                      />
                      <p className="logo-bottom-text">
                        Start Fresh. Work Smart. Be Safe.
                      </p>
                    </div>
                  </Col>
                  <Col md="12">{children}</Col>
                </Row>
              </div>
              <div className="right-bx">
                <div className="slider-login">
                  <img src={LoginPageGrapic} />
                  <div className="slide-content hidden">
                    <h2>Check Your Project Progress</h2>
                    <p>
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default LoginLayout;
