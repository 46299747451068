import {
  FRONTEMPLOYER_TESTORDER_LIST_LOADING,
  FRONTEMPLOYER_TESTORDER_RESET,
  FRONTEMPLOYER_TESTORDER_LOAD_PAGE,
  FRONTEMPLOYER_TESTORDER_ERROR,
  FRONTEMPLOYER_TESTORDER_CHANGE_STATUS,
  FRONTEMPLOYER_TESTORDER_CREATED,
  FRONTEMPLOYER_TESTORDER_LOADING_ON_SUBMIT,
  FRONTEMPLOYER_TESTORDER_GET_BY_ID,
  FRONTEMPLOYER_TESTORDER_UPDATED,
  FRONTEMPLOYER_TESTORDER_LIST_UPDATED,
  FRONTEMPLOYER_TESTORDER_QUALIFICATION_DELETE,
  FRONTEMPLOYER_TESTORDER_DELETE,
  FRONTEMPLOYER_TESTORDER_UPLOADED,
  FRONTEMPLOYER_TESTORDER_EMPLOYEES_LIST,
  FRONTEMPLOYER_TESTORDER_STAFF_LIST,
  FRONTEMPLOYER_TESTORDER_PHYSICIAN_LIST,
  FRONTEMPLOYER_TESTORDER_PHLEBOTOMIST_LIST,
  FRONTEMPLOYER_TESTORDER_NURSE_LIST,
  FRONTEMPLOYER_TESTORDER_SEARCH_PARAMATERS_UPDATE,
  FRONTEMPLOYER_TESTORDER_CANCEL_COMPLETE,
  FRONTEMPLOYER_TESTORDER_CANCEL_SUBMIT,
  FRONTEMPLOYER_TESTORDER_PATIENT_FORM_UPLOADED,
} from "actions/types";
import * as Constants from "constants/index";
//
const initialState = {
  testOrderList: {
    page: 1,
    data: [],
    count: 0,
  },
  employeeList: [],
  currentTestOrder: null,
  loadingTestOrderList: true,
  loadingTestOrder: true,
  // loadingUploadTestOrder: false,
  loadingListData: true,
  error: {},
  sortingParams: {
    limit: Constants.DEFAULT_PAGE_SIZE,
    page: 1,
    orderBy: "created_at",
    ascending: "desc",
    query: "",
    filters: [],
  },
  performDelete: false,
  physicianList: [],
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case FRONTEMPLOYER_TESTORDER_RESET:
      return {
        ...initialState,
        loadingTestOrder: state.loadingTestOrder,
        loadingTestOrderList: state.loadingTestOrderList,
        loadingListData: state.loadingListData,
      };
    case FRONTEMPLOYER_TESTORDER_LOAD_PAGE:
      return {
        ...state,
        loadingTestOrder: false,
        loadingListData: false,
        // loadingUploadTestOrder: false
      };
    case FRONTEMPLOYER_TESTORDER_CREATED:
      return {
        ...state,
        loadingTestOrder: false,
      };
    case FRONTEMPLOYER_TESTORDER_UPDATED:
      return {
        ...state,
        currentTestOrder: payload,
        sortingParams: initialState.sortingParams,
        loadingTestOrder: false,
      };
    case FRONTEMPLOYER_TESTORDER_CANCEL_SUBMIT:
      return {
        ...state,
        loadingCancelTestOrder: true,
      };
    case FRONTEMPLOYER_TESTORDER_CANCEL_COMPLETE:
      return {
        ...state,
        loadingCancelTestOrder: false,
      };
    case FRONTEMPLOYER_TESTORDER_DELETE:
      const currentCount = state.testOrderList.count;
      const currentLimit = state.sortingParams.limit;
      const currentPage = parseInt(state.testOrderList.page);
      var remainingPages = Math.ceil((currentCount - 1) / currentLimit);
      remainingPages = remainingPages ? remainingPages : 1;
      return {
        ...state,
        testOrderList: {
          data: state.testOrderList.data.filter(
            (employee) => employee._id !== payload
          ),
          count: currentCount - 1,
          page:
            currentPage <= remainingPages
              ? currentPage.toString()
              : remainingPages.toString(),
        },
        performDelete: true,
        loadingListData: false,
      };
    case FRONTEMPLOYER_TESTORDER_ERROR:
      return {
        ...state,
        error: payload,
        loadingTestOrder: false,
        loadingTestOrderList: false,
        loadingListData: false,
        performDelete: false,
      };
    case FRONTEMPLOYER_TESTORDER_EMPLOYEES_LIST:
      return {
        ...state,
        employeeList: payload,
      };
    case FRONTEMPLOYER_TESTORDER_STAFF_LIST:
      return {
        ...state,
        staffList: payload,
      };
    case FRONTEMPLOYER_TESTORDER_PHYSICIAN_LIST:
      return {
        ...state,
        physicianList: payload,
      };
    case FRONTEMPLOYER_TESTORDER_PHLEBOTOMIST_LIST:
      return {
        ...state,
        phlebotomistList: payload,
      };
    case FRONTEMPLOYER_TESTORDER_NURSE_LIST:
      return {
        ...state,
        nurseList: payload,
      };
    case FRONTEMPLOYER_TESTORDER_GET_BY_ID:
      return {
        ...state,
        currentTestOrder: payload,
        loadingTestOrder: false,
      };
    case FRONTEMPLOYER_TESTORDER_QUALIFICATION_DELETE:
      return {
        ...state,
        currentTestOrder: {
          ...state.currentTestOrder,
          qualifications: state.currentTestOrder.qualifications.filter(
            (cert) => cert._id !== payload
          ),
        },
        sortingParams: initialState.sortingParams,
        loadingTestOrder: false,
      };
    case FRONTEMPLOYER_TESTORDER_LIST_UPDATED:
      return {
        ...state,
        testOrderList: {
          data: payload.data,
          page: payload.metadata[0].current_page,
          count: payload.metadata[0].totalRecord,
        },
        loadingTestOrderList: false,
        loadingListData: false,
        performDelete: false,
      };
    case FRONTEMPLOYER_TESTORDER_SEARCH_PARAMATERS_UPDATE:
      return {
        ...state,
        sortingParams: { ...payload },
      };
    case FRONTEMPLOYER_TESTORDER_CHANGE_STATUS:
      return {
        ...state,
        testOrderList: {
          ...state.testOrderList,
          data: state.testOrderList.data.map((employee) =>
            employee._id === payload._id
              ? { ...employee, status: payload.status }
              : employee
          ),
        },
        loadingListData: false,
      };
    case FRONTEMPLOYER_TESTORDER_LIST_LOADING:
      return {
        ...state,
        loadingListData: true,
      };
    case FRONTEMPLOYER_TESTORDER_LOADING_ON_SUBMIT:
      return {
        ...state,
        loadingTestOrder: true,
        // loadingUploadTestOrder: true
      };
    case FRONTEMPLOYER_TESTORDER_PATIENT_FORM_UPLOADED:
      return {
        ...state,
        currentTestOrder: {
          ...state.currentTestOrder,
          employees: state.currentTestOrder.employees.map((eachEmp) =>
            eachEmp._id === payload.patientID
              ? {
                  ...eachEmp,
                  screeningFormPDF:
                    payload.screeningFormPDF || eachEmp.screeningFormPDF,
                  consentForm: payload.consentForm || eachEmp.consentForm,
                  requisitionFormPDF:
                    payload.requisitionFormPDF || eachEmp.requisitionFormPDF,
                }
              : eachEmp
          ),
        },
      };
    default:
      return state;
  }
}
